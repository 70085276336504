// Slider s lidma
#career .people-slider {
	@media (max-width: emBreakpoint($tabletWidth - 0.1rem)) {
		height: 34rem;

		> div h3 {
			margin-bottom: 0.9rem;
		}
	}

	> div {
		h3 {
			text-transform: none;

			span:first-of-type {
				text-transform: uppercase;
			}
		}
		p {
			white-space: nowrap;
		}
	}
}

// Formular pro zajemce o praci
#career-form {
	margin-top: 3rem;
	background: $transparentAquamarine;
	@include skew();

	form {
		position: relative;
		padding: 4.5rem 1.5rem 6rem;
		text-align: center;
		@include unskew();

		@media (min-width: emBreakpoint($mobilePlusWidth)) {
			padding-right: 3rem;
			padding-left: 3rem;
		}
	}
	h2 {
		margin: 0 0 3rem 0;
		padding: 0;
		color: $magenta;
	}
	.positions {
		margin-bottom: 4rem;
		text-align: left;

		> div {
			display: block;
			margin-bottom: 1rem;
		}
		input {
			position: absolute;
			width: 0;
			height: 0;
			opacity: 0;
		}
		label {
			display: block;
			padding: 1.1rem 1.5rem 1.3rem;
			color: $magenta;
			font-size: 1.6rem;
			line-height: 1.2;
			text-align: center;
			border: 0.1rem solid change-color($magenta, $alpha: 0.14);
			cursor: pointer;
		}
		label:hover,
		input:focus + label,
		input:checked + label {
			color: #fff;
			background: $magenta;
		}
	}
	input[type="text"],
	input[type="email"],
	input[type="tel"],
	textarea {
		display: block;
		width: 100%;
		margin-bottom: 1rem;
		font-weight: $regularFont;
		font-size: 1.6rem;
		background: #fff;

		&:valid,
		&:invalid {
			box-shadow: none;
		}
		&.error {
			box-shadow: 0 0 0.2rem 0.1rem red;
		}
	}
	input[type="text"],
	input[type="email"],
	input[type="tel"] {
		height: 4.4rem;
		padding: 0 1rem;
		line-height: 4.4rem;
	}
	textarea {
		min-height: 14rem;
		padding: 1rem;
		resize: vertical;
	}
	.file {
		margin: 2rem 0;
		text-align: left;

		input {
			position: absolute;
			width: 0;
			height: 0;
			opacity: 0;
		}
		label {
			display: block;
			height: 4.4rem;
			padding: 0 1.5rem;
			color: #fff;
			font-size: 1.6rem;
			line-height: 4.2rem;
			text-align: center;
			background: $aquamarine;
			cursor: pointer;
		}
		label:hover,
		input:focus + label,
		input:checked + label {
			text-decoration: underline;
		}
		input.error + label {
			box-shadow: 0 0 0.2rem 0.1rem red;
		}
		span {
			display: block;
			margin-top: 1rem;
			font-size: 1.4rem;
		}
		small {
			display: inline-block;
			margin-right: 0.8rem;
			padding: 0 0.8rem;
			color: #fff;
			font-size: inherit;
			line-height: 2.5rem;
			text-transform: uppercase;
			vertical-align: baseline;
			background: $aquamarine;
		}
	}
	.checkbox {
		position: relative;
		margin-top: 2rem;
		margin-bottom: 1rem;
		text-align: left;

		input {
			position: absolute;
			width: 0;
			height: 0;
			opacity: 0;
		}
		label {
			display: inline-block;
			padding-left: 3.5rem;
			font-size: 1.4rem;
			line-height: 1.8;
			cursor: pointer;

			&:after {
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				box-sizing: border-box;
				width: 2.5rem;
				height: 2.5rem;
				line-height: 2.5rem;
				text-align: center;
				background: #fff;
				border: 0.1rem solid $aquamarine;
				content: "";
				pointer-events: none;
			}
		}
		input:checked + label:after {
			background: url(../img/checkbox-check.svg) center center no-repeat $aquamarine;
			background-size: 1.2rem 0.9rem;
		}
		input.error + label:after {
			box-shadow: 0 0 0.2rem 0.1rem red;
		}
		a {
			color: inherit;
			font-weight: $boldFont;
			text-decoration: underline;

			&:hover,
			&:focus,
			&:active {
				text-decoration: none;
			}
		}
	}
	.input-message {
		display: block !important;
		margin-top: 0.5rem;
		margin-bottom: 1rem;
		color: $aquamarine;
		font-weight: $boldFont;
		font-size: 1.4rem;
		line-height: 1.4;
		text-align: left;

		&.error {
			color: red;
		}
	}
	input + .input-message {
		margin-top: -0.5rem;
	}
	.message {
		position: relative;
		max-height: 10rem;
		margin-top: 3rem;
		padding: 2rem 2rem 2rem 6rem;
		overflow: hidden;
		color: #fff;
		font-weight: $boldFont;
		font-size: 1.6rem;
		line-height: 1.4;
		text-align: left;
		background: $aquamarine;
		transition: all 0.3s 0.3s;

		&:after {
			position: absolute;
			top: 50%;
			left: 1.5rem;
			display: block;
			box-sizing: border-box;
			width: 3rem;
			height: 3rem;
			text-align: center;
			background: url(../img/checkbox-check.svg) center center no-repeat;
			background-size: 2.4rem 1.8rem;
			transform: translateY(-50%);
			content: "";
			pointer-events: none;
		}
		&.error {
			background: red;

			&:after {
				font-size: 3.2rem;
				line-height: 3rem;
				background: transparent;
				content: "!";
			}
		}
		&:empty {
			max-height: 0;
			margin-bottom: 0;
			padding-top: 0;
			padding-bottom: 0;
			opacity: 0;
		}
	}
	button[type="submit"] {
		position: absolute;
		top: 100%;
		left: 50%;
		z-index: 1;
		transform: translateX(-50%) translateY(-50%);
		transition: all 0.3s;
	}
	form.sent button[type="submit"] {
		max-height: 0;
		padding-top: 0;
		padding-bottom: 0;
		opacity: 0;
	}

	@media (min-width: emBreakpoint($tabletWidth)) {
		max-width: $pageWidth;
		margin: 8rem auto 0;

		form {
			max-width: $maxTextWidth;
			margin: auto;
			padding-top: 8rem;
			padding-bottom: 8rem;
		}
		h2 {
			margin-bottom: 5rem;
		}
		.positions {
			> div {
				display: inline-block;
				margin-right: 1rem;
			}
		}
		.file {
			label {
				display: inline-block;
				width: 15rem;
				vertical-align: middle;
			}
			span {
				display: inline-block;
				margin: 0 0 0 2rem;
				vertical-align: middle;
			}
		}
		.message {
			margin-top: 4rem;
		}
	}
}

// Vypsane pozice, ktere shanime
#job-positions {
	position: relative;
	z-index: 3;
	margin-top: 3.4rem;
	margin-bottom: 7rem;
	text-align: center;
	@include skew();

	.row:first-of-type > div:first-of-type {
		border-top: 0.1rem solid $transparentAquamarine;
	}
	.row > div {
		border-bottom: 0.1rem solid $transparentAquamarine;

		h3 {
			display: inline-block;
			width: 75%;
			margin-top: 2.6rem;
			color: $aquamarine;
			font-size: 1.9rem;
			line-height: 1.4;
			text-transform: uppercase;
			@include unskew();
		}
		div.text {
			padding: 0.1rem 0;
			transition: margin-top 0.3s, background 0.3s;

			&:before {
				display: block;
				width: 15rem;
				margin: auto;
				border-bottom: 0.2rem solid $aquamarine;
				opacity: 0;
				transition: opacity 0.3s;
				content: "";
				@include unskew();
			}
		}
		div.wrapper {
			height: 0;
			padding: 0 1.5rem;
			overflow: hidden;
			opacity: 0;
			transition: height 0.3s, margin 0.3s, opacity 0.3s;

			@media (min-width: emBreakpoint($mobilePlusWidth)) {
				padding: 0 3rem;
			}
		}
		h4 {
			margin-top: 2.4rem;
			color: #000;
			font-weight: $extraBoldFont;
			font-size: 1.6rem;
			line-height: 1.4;
			text-transform: uppercase;
			@include unskew();
		}
		p {
			max-width: 40rem;
			margin: 0.5rem auto 1rem;
			color: #000;
			font-size: 1.6rem;
			line-height: 1.4;
			@include unskew();
		}
		a.btn {
			display: inline-block;
			margin-top: 1.2rem;
			margin-bottom: 1.8rem;
			padding: 0.7rem 3.4rem 0.9rem;
			color: #fff;
			font-size: 1.7rem;
			line-height: 1.2;
			background: $aquamarine;
			@include unskew();

			&:hover,
			&:focus,
			&:active {
				color: $aquamarine;
			}
		}
		a.arrow {
			display: inline-block;
			width: 3rem;
			height: 3rem;
			margin: 0.8rem 0.8rem 1.4rem;
			line-height: 3rem;
			border-radius: 50%;
			@include unskew();

			svg {
				width: 2.1rem;
				height: 0.8rem;
				fill: none;
				stroke: $aquamarine;
				stroke-width: 3;
			}
			&:hover,
			&:focus,
			&:active {
				background: $transparentAquamarine;
			}
		}

		// Otevrene detaily
		div.text.open {
			margin-top: 1.6rem;
			background: $transparentAquamarine;

			&:before {
				opacity: 1;
			}
			.wrapper {
				opacity: 1;
			}
			a.arrow svg {
				transform: rotate(180deg);
			}
		}
	}

	// Fialova barva na mobilu
	@media (max-width: emBreakpoint($tabletWidth - 0.1)) {
		.row:nth-of-type(odd) > div:nth-of-type(odd),
		.row:nth-of-type(even) > div:nth-of-type(even) {
			h3 {
				color: $magenta;
			}
			div.text {
				&.open {
					background: $transparentMagenta;
				}
				&:before {
					border-color: $magenta;
				}
			}
			a.btn {
				background: $magenta;

				&:hover,
				&:focus,
				&:active {
					color: $magenta;
				}
			}
			a.arrow {
				svg {
					stroke: $magenta;
				}
				&:hover,
				&:focus,
				&:active {
					background: $transparentMagenta;
				}
			}
		}
	}

	// Fialova barva na desktopu
	@media (min-width: emBreakpoint($tabletWidth)) {
		.row:nth-of-type(odd) > div:nth-of-type(even),
		.row:nth-of-type(even) > div:nth-of-type(odd) {
			h3 {
				color: $magenta;
			}
			div.text {
				background: $transparentMagenta;

				&:before {
					border-color: $magenta;
				}
			}
			a.btn {
				background: $magenta;

				&:hover,
				&:focus,
				&:active {
					color: $magenta;
				}
			}
		}
	}

	// Desktopova verze pozic
	@media (min-width: emBreakpoint($tabletWidth)) {
		margin-top: 4.4rem;
		margin-bottom: 16rem;

		.row {
			@include flexbox();
			position: relative;
			max-width: $pageWidth;
			margin: auto;
		}
		.row > div {
			@include flexbox();
			position: relative;
			width: 33.3%;
			padding: 16rem 1% 2rem 1%;
			border: none !important;

			h3 {
				position: absolute;
				bottom: calc(100% - 13.5rem);
				left: 50%;
				margin-top: 0;
				font-weight: $extraBoldFont;
				font-size: 2.8rem;
				@include unskew(translateX(-50%));
			}
			div.text {
				width: 100%;
				margin-top: 0 !important;
				padding-bottom: 4rem;
				background: $transparentAquamarine;

				&:before {
					width: 18rem;
					border-width: 0.3rem;
					opacity: 1;
				}
			}
			div.wrapper {
				height: auto !important;
				overflow: visible;
				opacity: 1 !important;
			}
			h4 {
				margin-top: 3rem;
			}
			p {
				max-width: none;
				margin-bottom: 1rem;
				font-size: 1.7rem;
			}
			a.btn {
				position: absolute;
				bottom: 0;
				left: 50%;
				@include unskew(translateX(-50%) translateY(50%));
			}
			a.arrow {
				display: none;
			}
		}
	}
	@media (min-width: emBreakpoint($desktopWidth)) {
		.row {
			padding: 0 6rem;
		}
		.row > div {
			padding-right: 2%;
			padding-left: 2%;
		}
	}
}

// Detail pozice
.job-intro {
	padding: 0 1.5rem;
	text-align: center;

	@media (min-width: emBreakpoint($mobilePlusWidth)) {
		padding: 0 3rem;
	}

	> * {
		max-width: $maxTextWidth;
	}
	h1,
	h2 {
		margin: 0;
		padding: 0;
		color: $magenta;
	}
	h3 {
		display: inline-block;
		margin: 1.5rem 0 2rem;
		color: #fff;
		font-weight: $extraBoldFont;
		font-size: 1.8rem;
		line-height: 3.9rem;
		text-transform: uppercase;

		span {
			padding: 0.4rem 2.2rem;
			background: $magenta;
		}
	}
	p,
	blockquote {
		margin-bottom: 1.5rem;
		font-size: 1.6rem;
		line-height: 1.6;
		text-align: left;

		&:last-child {
			margin-bottom: 0;
		}
	}
	p b,
	blockquote {
		font-weight: $boldFont;
	}

	@media (min-width: emBreakpoint($tabletWidth)) {
		max-width: $pageWidth;
		margin: auto;
		padding-bottom: 1rem;
		text-align: left;

		> * {
			width: 60%;
		}
		h1,
		h2 {
			text-align: left;
		}
		h3 {
			margin: 2rem 0 2.5rem;
			font-size: 3rem;
			line-height: 5.7rem;
		}
	}
	@media (min-width: emBreakpoint($desktopWidth)) {
		padding-left: 10rem;
	}
}

.job-description {
	margin-top: 33rem;
	text-align: center;
	background: $transparentAquamarine;
	@include skew();

	> img {
		position: absolute;
		bottom: 100%;
		left: 50%;
		width: auto;
		height: 31rem;
		transform-origin: left bottom;
		@include unskew(translateX(-50%));
	}
	> div {
		position: relative;
		top: -2rem;
		max-width: $maxTextWidth;
		margin: auto;
		padding: 0 1.5rem 7%;
		@include unskew();

		@media (min-width: emBreakpoint($mobilePlusWidth)) {
			padding-right: 3rem;
			padding-left: 3rem;
		}
	}
	h3 {
		display: inline-block;
		margin-bottom: 3rem;
		padding: 1rem 2.2rem;
		color: #fff;
		font-weight: $extraBoldFont;
		font-size: 1.8rem;
		text-transform: uppercase;
		background: $aquamarine;
	}
	h3 ~ h3 {
		margin-top: 4rem;
	}
	.highlights {
		font-size: 0;

		li {
			display: inline-block;
			font-weight: $boldFont;
			font-size: 1.6rem;
			line-height: 1.6;
			text-transform: uppercase;

			&:after {
				margin: 0 0.8rem;
				color: $magenta;
				font-weight: $extraBoldFont;
				font-size: 1.6rem;
				content: "|";
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
	p,
	ul:not(.highlights) {
		margin-top: 2rem;
		font-size: 1.6rem;
		line-height: 1.4;
		text-align: left;

		b {
			font-weight: $boldFont;
		}
	}
	ul:not(.highlights) li {
		position: relative;
		margin-top: 1rem;
		padding-left: 2.2rem;

		&:before {
			position: absolute;
			top: 0.8rem;
			left: 0;
			display: block;
			width: 0.8rem;
			height: 0.8rem;
			background: $magenta;
			content: "";
		}
	}

	@media (min-width: emBreakpoint($tabletWidth)) {
		max-width: $pageWidth;
		margin: 8rem auto;

		> img {
			left: 65%;
			height: 50rem;
			@include unskew();
		}
		> div {
			top: -2.5rem;
			padding-bottom: 5.4rem;
		}
		h3 {
			font-size: 3rem;
		}
	}
}
