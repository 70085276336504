// Sekce s klienty
#all-clients {
	position: relative;
	z-index: 3;
	text-align: center;
	@include skew();

	.row:first-of-type > div:first-of-type {
		border-top: 0.1rem solid $transparentAquamarine;
	}
	.row > div {
		border-bottom: 0.1rem solid $transparentAquamarine;

		img {
			display: inline-block;
			height: auto;
			margin-top: 2.6rem;
			@include unskew();

			&[src*="philip-morris"] {
				width: 12.7rem;
			}
			&[src*="kooperativa"] {
				width: 12rem;
			}
			&[src*="mcdonald"] {
				width: 6.3rem;
			}
			&[src*="csob"] {
				width: 7.5rem;
			}
			&[src*="ovb"] {
				width: 6rem;
			}
			&[src*="obi"] {
				width: 10.5rem;
			}
		}
		div.text {
			padding: 0.1rem 0;
			transition: margin-top 0.3s, background 0.3s;

			&:before {
				display: block;
				width: 15rem;
				margin: auto;
				border-bottom: 0.2rem solid $aquamarine;
				opacity: 0;
				transition: opacity 0.3s;
				content: "";
				@include unskew();
			}
		}
		div.wrapper {
			height: 0;
			padding: 0 1.5rem;
			overflow: hidden;
			opacity: 0;
			transition: height 0.3s, margin 0.3s, opacity 0.3s;

			@media (min-width: emBreakpoint($mobilePlusWidth)) {
				padding: 0 3rem;
			}
		}
		h4 {
			margin-top: 2.4rem;
			color: #000;
			font-weight: $extraBoldFont;
			font-size: 1.6rem;
			line-height: 1.4;
			text-transform: uppercase;
			@include unskew();
		}
		p {
			max-width: 40rem;
			margin: 0.5rem auto 1rem;
			color: #000;
			font-size: 1.6rem;
			line-height: 1.4;
			@include unskew();
		}
		a.arrow {
			display: inline-block;
			width: 3rem;
			height: 3rem;
			margin: 0.8rem 0.8rem 1.4rem;
			line-height: 3rem;
			border-radius: 50%;
			@include unskew();

			svg {
				width: 2.1rem;
				height: 0.8rem;
				fill: none;
				stroke: $aquamarine;
				stroke-width: 3;
			}
			&:hover,
			&:focus,
			&:active {
				background: $transparentAquamarine;
			}
		}

		// Otevrene detaily
		div.text.open {
			margin-top: 1.6rem;
			background: $transparentAquamarine;

			&:before {
				opacity: 1;
			}
			.wrapper {
				opacity: 1;
			}
			a.arrow svg {
				transform: rotate(180deg);
			}
		}
	}

	// Desktopova verze klientu
	@media (min-width: emBreakpoint($tabletWidth)) {
		.row {
			@include flexbox();
			position: relative;
			max-width: $pageWidth;
			margin: auto;
		}
		.row > div {
			@include flexbox();
			position: relative;
			width: 33.3%;
			padding: 16rem 1% 2rem 1%;
			border: none !important;

			img {
				position: absolute;
				bottom: calc(100% - 13.5rem);
				left: 50%;
				margin-top: 0;
				@include unskew(translateX(-50%));

				&[src*="philip-morris"] {
					width: 16.9rem;
				}
				&[src*="kooperativa"] {
					bottom: calc(100% - 14rem);
					width: 16.1rem;
				}
				&[src*="mcdonald"] {
					width: 8.4rem;
				}
				&[src*="csob"] {
					width: 10rem;
				}
				&[src*="ovb"] {
					width: 7.9rem;
				}
				&[src*="obi"] {
					width: 15rem;
				}
			}
			div.text {
				width: 100%;
				margin-top: 0 !important;
				padding-bottom: 4rem;
				background: $transparentAquamarine;

				&:before {
					width: 18rem;
					border-width: 0.3rem;
					opacity: 1;
				}
			}
			div.wrapper {
				height: auto !important;
				overflow: visible;
				opacity: 1 !important;
			}
			h4 {
				margin-top: 3rem;
			}
			p {
				max-width: none;
				margin-bottom: 1rem;
				font-size: 1.7rem;
			}
			a.arrow {
				display: none;
			}
		}

		// Fialova barva
		.row:nth-of-type(odd) > div:nth-of-type(even),
		.row:nth-of-type(even) > div:nth-of-type(odd) {
			div.text {
				background: $transparentMagenta;

				&:before {
					border-color: $magenta;
				}
			}
		}
	}
	@media (min-width: emBreakpoint($desktopWidth)) {
		.row {
			padding: 0 6rem;
		}
		.row > div {
			padding-right: 2%;
			padding-left: 2%;
		}
	}
}
