// Rozmisteni, velikost, obecny reset
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-size: 100%;
	font-family: inherit;
	font-style: normal;
	border: none;
	outline: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
html {
	width: 100%;
	font-size: $defaultFontSize;
	scroll-behavior: smooth;
}
body {
	width: 100%;
	line-height: 1;
	-webkit-text-size-adjust: none;
}

// Vzhled specifickych prvku
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;

	&:before,
	&:after {
		content: "";
		content: none;
	}
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a {
	text-decoration: none;
}
input,
select,
textarea,
button {
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	&::-moz-focus-inner {
		border: 0;
	}
}
a,
input,
textarea,
select,
button {

	&:hover,
	&:focus,
	&:active {
		outline: none;
	}
}
select:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #000;
}

// Vyber textu
::-moz-selection {
	color: #fff;
	text-shadow: none;
	background: #000;
}
::selection {
	color: #fff;
	text-shadow: none;
	background: #000;
}
:active {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
