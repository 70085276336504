.video-container {
	position: relative;
	height: 0;
	margin-right: auto;
	margin-left: auto;
}

.video-container.is-playing {
	.video-container__play-overlay {
		display: none !important;
	}
}

.video-container--16-9 {
	padding-bottom: 56.25%;
}

.video-container__video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video-container__play-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	font-size: 0;
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
}
