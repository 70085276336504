// Variables
$black: #000000;
$white: #ffffff;
$gray: #707070;
$magenta: #b51881;
$aquamarine: #03dcbb;
$transparentMagenta: change-color($magenta, $alpha: 0.15);
$transparentAquamarine: change-color($aquamarine, $alpha: 0.15);

$defaultFontSize: 62.5%;

$regularFont: 300;
$boldFont: 600;
$extraBoldFont: 800;

$mobileWidth: 32rem;
$mobilePlusWidth: 36rem;
$mobileBigWidth: 43rem;
$tabletWidth: 76.8rem;
$tabletBigWidth: 102.4rem;
$desktopWidth: 110rem;
$pageWidth: 144rem;

$maxTextWidth: 72rem;


// Funkce pro vraceni EM hodnoty pro breakpointy (bere REM jednotky)
@function emBreakpoint($remSize) {
	$emSize: $remSize * ($defaultFontSize / 1%) / 100rem;
	@return #{$emSize}em;
}

// Mixins
@mixin skew($transform: null) {
	transform: $transform skew(0deg, -8deg);
}
@mixin unskew($transform: null) {
	transform: $transform skew(0deg, 8deg);
}


@mixin flexbox() {
	display: -ms-flexbox;
	display: flex;
}
@mixin user-select($user-select) {
	-webkit-user-select: $user-select;
	-moz-user-select: $user-select;
	-ms-user-select: $user-select;
	user-select: $user-select;
}
@mixin background-gradient($gradient...) {
	background: -webkit-linear-gradient($gradient);
	background: -moz-linear-gradient($gradient);
	background: -o-linear-gradient($gradient);
	background: linear-gradient($gradient);
}
@mixin backdrop-filter($backdrop) {
	-webkit-backdrop-filter: $backdrop;
	backdrop-filter: $backdrop;
}
