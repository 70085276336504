#about {
	@media (min-width: emBreakpoint($tabletWidth)) {
		&:after {
			margin-top: -9rem;
		}
	}

	.people-slider {
		background: $transparentMagenta;

		&:before,
		&:after {
			background: $transparentMagenta;
		}
		h3 {
			span {
				background: $magenta;
			}
		}
	}

	#our-mission {
		max-width: $maxTextWidth;
		margin: 6rem auto;
		background: $transparentAquamarine;
		@include skew();

		> div {
			position: relative;
			top: -2rem;
			max-width: 40rem;
			margin: auto;
			padding: 0 1.5rem 3rem;
			@include unskew();

			@media (min-width: emBreakpoint($mobilePlusWidth)) {
				padding-right: 3rem;
				padding-left: 3rem;
			}
		}
		h3 {
			display: inline-block;
			margin-bottom: 3rem;
			padding: 1rem 2.2rem;
			color: #fff;
			font-weight: $extraBoldFont;
			font-size: 1.8rem;
			text-transform: uppercase;
			background: $aquamarine;
		}
		p {
			font-size: 1.6rem;
			line-height: 1.4;

			b {
				font-weight: $boldFont;
			}
		}

		@media (min-width: emBreakpoint($tabletWidth)) {
			margin-top: 13rem;

			> div {
				top: -2.5rem;
			}
			h3 {
				font-size: 3rem;
			}
			p {
				font-size: 2.2rem;
			}
		}
	}

	#values-slider {
		margin-top: 3rem;

		@media (min-width: emBreakpoint($tabletWidth)) {
			margin-top: 5rem;
		}

		.icons {
			height: 9rem;
			overflow: hidden;
			font-size: 0;
			line-height: 9rem;
			white-space: nowrap;

			div {
				display: inline-block;
				width: 9rem;
				height: 9rem;
				margin: 0 0.5rem;
				vertical-align: middle;
				transition: width 0.3s, height 0.3s, margin 0.3s;

				svg {
					display: block;
					width: 100%;
					height: 100%;
				}
				&:nth-of-type(2),
				&:nth-of-type(6) {
					width: 2.5rem;
					height: 2.5rem;
				}
				&:nth-of-type(3),
				&:nth-of-type(5) {
					width: 5rem;
					height: 5rem;
				}
				&.invisible {
					width: 0;
					height: 0;
					margin: 0;
				}
			}

			@media (min-width: emBreakpoint($mobilePlusWidth)) {
				div {
					margin: 0 0.7rem;

					&:nth-of-type(2),
					&:nth-of-type(6) {
						width: 3.5rem;
						height: 3.5rem;
					}
					&:nth-of-type(3),
					&:nth-of-type(5) {
						width: 6rem;
						height: 6rem;
					}
				}
			}
			@media (min-width: emBreakpoint($tabletWidth)) {
				height: 13rem;
				line-height: 13rem;

				div {
					width: 13rem;
					height: 13rem;
					margin: 0 1.2rem;

					&:nth-of-type(2),
					&:nth-of-type(6) {
						width: 6rem;
						height: 6rem;
					}
					&:nth-of-type(3),
					&:nth-of-type(5) {
						width: 9rem;
						height: 9rem;
					}
				}
			}
		}
		.values {
			max-width: $maxTextWidth;
			margin: 4rem auto 0;
			transition: background 0.3s;
			@include skew();

			.arrow {
				position: absolute;
				top: 50%;
				z-index: 2;
				display: block;
				width: 2.5rem;
				height: 5.7rem;
				text-align: center;
				@include unskew(translateY(-50%));

				&:first-of-type {
					left: 1rem;

					svg {
						transform: rotate(180deg);
					}
				}
				&:last-of-type {
					right: 1rem;
				}

				svg {
					display: inline-block;
					width: 1.4rem;
					height: 5.7rem;
					fill: none;
					stroke: #000;
					stroke-width: 0.1rem;
				}

				@media (min-width: emBreakpoint($mobilePlusWidth)) {
					&:first-of-type {
						left: 2.5rem;
					}
					&:last-of-type {
						right: 2.5rem;
					}
				}
			}
			> div {
				width: calc(100% - 7rem);
				margin: auto;
				@include unskew();

				> div {
					display: none;
					max-width: 40rem;
					margin: auto;
					padding: 5rem 1.5rem;

					&.visible {
						display: block;
					}
				}

				@media (min-width: emBreakpoint($mobilePlusWidth)) {
					left: 5rem;
					width: calc(100% - 10rem);

					> div {
						padding-right: 3rem;
						padding-left: 3rem;
					}
				}
			}

			h3 {
				position: absolute;
				top: -1.8rem;
				left: 50%;
				display: inline-block;
				margin-bottom: 3rem;
				padding: 1rem 2.2rem;
				color: #fff;
				font-weight: $extraBoldFont;
				font-size: 1.8rem;
				text-transform: uppercase;
				background: $aquamarine;
				transform: translateX(-50%);
			}
			p {
				font-weight: $boldFont;
				font-size: 1.6rem;
				line-height: 1.4;
			}

			&.partnership {
				background: change-color($color: #46368b, $alpha: 0.15);

				h3 {
					background: #46368b;
				}
			}
			&.freedom {
				background: change-color($color: #00aeb0, $alpha: 0.15);

				h3 {
					background: #00aeb0;
				}
			}
			&.responsibility {
				background: change-color($color: #e60064, $alpha: 0.15);

				h3 {
					background: #e60064;
				}
			}
			&.growth {
				background: change-color($color: #95c11f, $alpha: 0.15);

				h3 {
					background: #95c11f;
				}
			}
			&.fairness {
				background: change-color($color: #f39200, $alpha: 0.15);

				h3 {
					background: #f39200;
				}
			}

			@media (min-width: emBreakpoint($tabletWidth)) {
				margin-top: 6rem;

				> div > div {
					padding-top: 7rem;
					padding-bottom: 7rem;
				}
				h3 {
					top: -2.5rem;
					font-size: 3rem;
				}
				p {
					font-size: 2.2rem;
				}
			}
		}
	}
}
