#products {
	.anchor-id {
		position: relative;
		top: -77rem;
		display: block;
		visibility: hidden;

		@media (max-width: emBreakpoint($desktopWidth)) {
			top: -50rem;
		}

		@media (max-width: emBreakpoint($tabletWidth)) {
			top: -40rem;
		}

		@media (max-width: emBreakpoint($mobilePlusWidth)) {
			top: -30rem;
		}
	}

	#all-products {
		position: relative;
		z-index: 3;
		display: none;
		margin-bottom: 3rem;
		text-align: center;
		@include skew();

		@media (min-width: emBreakpoint($tabletWidth)) {
			display: block;
		}

		.row {
			@media (min-width: emBreakpoint($tabletWidth)) {
				@include flexbox();
				position: relative;
				max-width: $pageWidth;
				margin: auto;
			}

			@media (min-width: emBreakpoint($desktopWidth)) {
				padding: 0 6rem;
			}
		}

		.row > div {
			position: relative;
			padding: 4rem 1.5rem 0 1.5rem;

			@media (min-width: emBreakpoint($tabletWidth)) {
				@include flexbox();
				position: relative;
				width: 33.3%;
				padding: 5rem 1% 1rem 1%;
			}

			@media (min-width: emBreakpoint($desktopWidth)) {
				padding-right: 2%;
				padding-left: 2%;
			}

			div.text {
				width: 100%;
				margin-top: 0 !important;
				padding-top: 1.5rem;
				padding-bottom: 3.5rem;
				background: $transparentAquamarine;

				@media (min-width: emBreakpoint($tabletWidth)) {
					padding-top: 1rem;
					padding-bottom: 3rem;
				}
			}

			div.wrapper {
				padding: 0;

				@media (min-width: emBreakpoint($tabletWidth)) {
					height: auto !important;
				}
			}

			.text {
				a {
					position: relative;
					display: block;
					width: 13rem;
					margin-top: 2.4rem;
					margin-left: 2rem;
					color: #4d4d4d;
					font-weight: $extraBoldFont;
					font-size: 1.6rem;
					line-height: 1.4;
					text-transform: uppercase;
					@include unskew();

					@media (min-width: emBreakpoint($mobilePlusWidth)) {
						width: 15rem;
						margin-left: 2rem;
					}

					@media (min-width: emBreakpoint($mobileBigWidth)) {
						width: 18rem;
						margin-left: 5rem;
					}

					@media (min-width: emBreakpoint($tabletWidth)) {
						width: 14rem;
						margin-left: 1.5rem;
					}

					@media (min-width: emBreakpoint($tabletBigWidth)) {
						width: 18rem;
						margin-left: 2rem;
					}

					@media (min-width: 1200px) {
						margin-left: 4rem;
					}

					@media (min-width: emBreakpoint($pageWidth)) {
						margin-left: 6rem;
					}

					&:hover:before {
						width: 1rem;
					}

					&:before {
						position: absolute;
						bottom: -2.5rem;
						left: 50%;
						display: block;
						width: 100%;
						border-bottom: 0.2rem solid $aquamarine;
						transform: translateX(-50%);
						transition: width 0.5s;
						content: "";
					}
				}
			}

			img {
				position: absolute;
				right: 1rem;
				bottom: 0;
				width: 15.5rem;
				margin-top: 0;
				@include unskew();

				@media (min-width: emBreakpoint($tabletWidth)) {
					position: absolute;
					right: 0;
					bottom: 1.5rem;
					width: 11rem;
					margin-top: 0;
					@include unskew();
				}

				@media (min-width: emBreakpoint($pageWidth)) {
					width: 15.5rem;
				}
			}
		}

		// Fialova barva
		.row:nth-of-type(odd) > div:nth-of-type(even),
		.row:nth-of-type(even) > div:nth-of-type(odd) {
			div.text {
				background: $transparentMagenta;

				a {
					&:before {
						border-color: $magenta;
					}
				}
			}
		}
	}

	#all-products-details {
		position: relative;
		z-index: 3;
		margin-bottom: 11rem;
		text-align: center;
		// @include skew();

		@media (min-width: emBreakpoint($mobileBigWidth)) {
			margin-bottom: 5rem;
		}

		@media (min-width: emBreakpoint($tabletWidth)) {
			margin-bottom: 11rem;
		}

		.anchor-id {
			position: relative;
			top: -12rem;
			display: block;
			visibility: hidden;

			@media (max-width: emBreakpoint($desktopWidth)) {
				top: -10rem;
			}

			@media (max-width: emBreakpoint($tabletWidth)) {
				top: -40rem;
			}

			@media (max-width: emBreakpoint($mobilePlusWidth)) {
				top: -30rem;
			}
		}

		.row {
			max-width: $pageWidth;
			margin: auto;

			@media (min-width: emBreakpoint($desktopWidth)) {
				padding: 0 calc(6rem + 2%);
			}
			&:nth-of-type(even) .text {
				text-align: left;
				a {
					&:before {
						border-bottom: 0.2rem solid $magenta;
					}
					color: $magenta;
					.arrow-right {
						border: solid $magenta;
					}
				}
			}
			&:nth-of-type(odd) .text {
				a {
					&:before {
						border-bottom: 0.2rem solid $aquamarine;
					}
					color: $aquamarine;
					.arrow-right {
						border: solid $aquamarine;
					}
				}
			}
		}

		.row > div {
			position: relative;
			width: 100%;
			margin: 16rem 0 0 0;

			@media (min-width: emBreakpoint($mobileBigWidth)) {
				min-height: 28rem;
				margin: 23rem 0 0 0;
			}

			@media (min-width: emBreakpoint($tabletWidth)) {
				display: flex;
				justify-content: flex-end;
				min-height: 35rem;
				margin: 5rem 0 0 0;
			}

			@media (min-width: emBreakpoint($desktopWidth)) {
				height: 37rem;
			}

			@media (min-width: emBreakpoint($pageWidth)) {
				height: 36rem;
				min-height: auto;
				margin: 7rem 0 0 0;
			}

			&:after {
				position: absolute;
				top: 12%;
				right: 0;
				z-index: -1;
				width: 100%;
				height: 100%;
				background: $transparentAquamarine;
				content: "";
				@include skew();
			}

			div.text {
				width: 100%;
				padding: 7rem 1.5rem 1rem 1.5rem;

				@media (min-width: emBreakpoint($mobileBigWidth)) {
					padding: 7rem 1.5rem 2rem 1.5rem;
				}

				@media (min-width: emBreakpoint($tabletWidth)) {
					width: 60%;
					padding: 2rem 3.5rem 0 3.5rem;
					text-align: right;
				}

				@media (min-width: emBreakpoint($desktopWidth)) {
					padding: 4rem 3.5rem 0 1.5rem;
				}

				@media (min-width: emBreakpoint($pageWidth)) {
					width: 50%;
					padding: 2rem 3.5rem 0 1.5rem;
				}

				.wrapper {
					height: 100%;
					// @include unskew();
				}
			}

			h2 {
				position: relative;
				display: inline-block;
				margin-top: 2rem;
				margin-bottom: 0;
				padding: 0 1rem;
				color: #fff;
				font-weight: $extraBoldFont;
				font-size: 2.4rem;
				line-height: 3.6rem;
				text-transform: none;
				background: $aquamarine;

				@media (min-width: emBreakpoint($desktopWidth)) {
					margin-top: 0;
					font-size: 4.1rem;
					line-height: 6.1rem;
				}
			}

			p {
				margin-top: 1.5rem;
				color: $gray;
				font-size: 1.6rem;
				line-height: 2.4rem;

				@media (min-width: emBreakpoint($desktopWidth)) {
					margin-top: 2.5rem;
					font-size: 1.8rem;
					line-height: 2.4rem;
				}

				@media (min-width: emBreakpoint($pageWidth)) {
					margin-top: 2rem;
				}

				// &.description-short {
				//	@media (min-width: emBreakpoint($tabletWidth)) {
				//		max-width: 33rem;
				//	}
				//
				//	@media (min-width: emBreakpoint($tabletBigWidth)) {
				//		max-width: 50rem;
				//	}
				//
				//	@media (min-width: emBreakpoint($pageWidth)) {
				//		max-width: none;
				//	}
				// }
			}

			.img-wrapper {
				position: absolute;
				top: -10rem;
				left: 50%;
				width: 70%;
				height: 18rem;
				margin: auto;
				// @include unskew(translateX(-50%));
				transform: translateX(-50%);

				@media (min-width: emBreakpoint($mobileBigWidth)) {
					top: -17rem;
					height: 25rem;
				}

				@media (min-width: emBreakpoint($tabletWidth)) {
					left: 0;
					width: 40%;
					height: auto;
					max-height: 35rem;
					// @include unskew(translateX(0));
					transform: translateX(0);
				}

				@media (min-width: emBreakpoint($desktopWidth)) {
					width: 40rem;
					max-height: 35rem;
				}

				@media (min-width: emBreakpoint($pageWidth)) {
					width: 54rem;
					max-height: 45rem;
				}

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}

		.row > div.product {
			.text {
				a {
					position: relative;
					font-weight: 700;
					font-size: 1.8rem;
					line-height: 2.4rem;

					// Podtržítko s hover effectem
					&:before {
						position: absolute;
						bottom: -0.5rem;
						left: 50%;
						display: block;
						width: 100%;
						transform: translateX(-50%);
						transition: width 0.5s;
						content: "";
					}

					&:hover,
					&:focus {
						&:before {
							width: 1rem;
						}

						.arrow-right {
							transform: rotate(-45deg) translate(0.1rem, 0.1rem);
						}
					}

					// Šipka doprava u odkazu
					.arrow-right {
						display: inline-block;
						padding: 0.5rem;
						border-width: 0 0.3rem 0.3rem 0;
						transform: rotate(-45deg);
						transition: all 0.5s;
					}
				}
				p {
					margin-bottom: 1rem;
				}
			}
		}

		.row > div.bonopot {
			.img-wrapper {
				@media (min-width: emBreakpoint($tabletWidth)) {
					bottom: -34rem;
				}

				@media (min-width: emBreakpoint($desktopWidth)) {
					bottom: -36rem;
				}

				@media (min-width: emBreakpoint($pageWidth)) {
					max-height: 42rem;
				}
			}
		}

		.row > div.posmio {
			.img-wrapper {
				@media (min-width: emBreakpoint($tabletWidth)) {
					bottom: -17rem;
				}

				@media (min-width: emBreakpoint($desktopWidth)) {
					bottom: -17rem;
				}

				@media (min-width: emBreakpoint($pageWidth)) {
					right: -5rem !important;
					bottom: -11rem;
					width: 56rem;
				}
			}
		}

		.row > div.survey {
			.img-wrapper {
				@media (min-width: emBreakpoint($tabletWidth)) {
					bottom: -35rem;
				}

				@media (min-width: emBreakpoint($desktopWidth)) {
					bottom: -38rem;
				}

				@media (min-width: emBreakpoint($pageWidth)) {
					bottom: -37rem;
				}
			}
		}

		.row > div.sms-brana {
			.img-wrapper {
				@media (min-width: emBreakpoint($tabletWidth)) {
					bottom: -17rem;
				}

				@media (min-width: emBreakpoint($desktopWidth)) {
					bottom: -14rem;
				}

				@media (min-width: emBreakpoint($pageWidth)) {
					width: 52rem;
				}
			}
		}

		.row > div.mgm {
			.img-wrapper {
				@media (min-width: emBreakpoint($tabletWidth)) {
					bottom: -35rem;
				}

				@media (min-width: emBreakpoint($desktopWidth)) {
					bottom: -39rem;
				}

				@media (min-width: emBreakpoint($pageWidth)) {
					bottom: -41rem;
					width: 50rem;
				}
			}
		}

		.row > div.planner {
			.img-wrapper {
				@media (min-width: emBreakpoint($tabletWidth)) {
					bottom: -21rem;
				}

				@media (min-width: emBreakpoint($desktopWidth)) {
					bottom: -19rem;
				}

				@media (min-width: emBreakpoint($pageWidth)) {
					right: -4rem !important;
					bottom: -15rem;
					width: 54rem;
				}
			}
		}

		.row > div.generator-kodu {
			.img-wrapper {
				@media (min-width: emBreakpoint($tabletWidth)) {
					bottom: -27rem;
				}

				@media (min-width: emBreakpoint($desktopWidth)) {
					bottom: -26rem;
				}

				@media (min-width: emBreakpoint($pageWidth)) {
					bottom: -34rem;
				}
			}
		}

		// Fialova barva
		.row:nth-of-type(odd) > div:nth-of-type(even),
		.row:nth-of-type(even) > div:nth-of-type(odd) {
			&:after {
				background: $transparentMagenta;
			}

			@media (min-width: emBreakpoint($tabletWidth)) {
				justify-content: flex-start;
			}

			.img-wrapper {
				@media (min-width: emBreakpoint($tabletWidth)) {
					right: 0;
					left: inherit;
					order: 2;
				}
			}

			div.text {
				@media (min-width: emBreakpoint($tabletWidth)) {
					order: 1;
					margin-top: 6.5%;
					padding: 6rem 1.5rem 0 3.5rem;
					text-align: left;
				}

				h2 {
					background: $magenta;
				}
			}

			// .anchor-id {
			//	position: relative;
			//	top: -25rem;
			//	display: block;
			//	visibility: hidden;
			//
			//	@media (max-width: emBreakpoint($desktopWidth)) {
			//		top: -50rem;
			//	}
			//
			//	@media (max-width: emBreakpoint($tabletWidth)) {
			//		top: -40rem;
			//	}
			//
			//	@media (max-width: emBreakpoint($mobilePlusWidth)) {
			//		top: -30rem;
			//	}
			// }
		}
	}

	// section {
	//	max-width: $pageWidth;
	//	margin: auto;
	//	text-align: center;
	// }
	// section.video {
	//	margin-top: -7%;
	// }
	// section.content + section.video {
	//	margin-top: -14%;
	// }
	// section.content {
	//	position: relative;
	//	z-index: 3;
	//	margin-bottom: 9rem;
	//	padding-top: 0.1rem;
	//	padding-bottom: 7.5rem;
	//	transform-origin: top left;
	//	@include skew();
	//
	//	&:last-of-type {
	//		margin-bottom: 0;
	//	}
	//	> * {
	//		@include unskew();
	//	}
	//	h2 {
	//		margin-top: 5rem;
	//
	//		img {
	//			width: 18rem;
	//		}
	//	}
	//	p {
	//		max-width: $maxTextWidth;
	//		margin: 2rem auto 0 auto;
	//		padding: 0 1.5rem;
	//		font-size: 1.6rem;
	//		line-height: 1.4;
	//		text-align: left;
	//	}
	//	ul {
	//		max-width: 74rem;
	//		margin: 2rem auto 0 auto;
	//		padding: 0 1.5rem;
	//		font-size: 1.6rem;
	//		line-height: 1.4;
	//		text-align: left;
	//		list-style-type: square;
	//
	//		li {
	//			margin-bottom: 0.8rem;
	//			margin-left: 1.7rem;
	//		}
	//	}
	//	.btn {
	//		position: absolute;
	//		top: 100%;
	//		left: 50%;
	//		@include unskew(translateX(-50%) translateY(-50%));
	//	}
	//
	//	@media (min-width: emBreakpoint($mobilePlusWidth)) {
	//		p,
	//		ul {
	//			padding: 0 3rem;
	//		}
	//	}
	// }
	//
	// .image-gallery {
	//	margin-top: 4.5rem;
	//	padding: 3rem 1.5rem;
	//	font-size: 0;
	//	background: #fff;
	//
	//	@media (min-width: emBreakpoint($mobilePlusWidth)) {
	//		padding: 3rem;
	//	}
	//
	//	.description {
	//		a {
	//			display: none;
	//			color: #000;
	//			font-weight: $extraBoldFont;
	//			font-size: 1.7rem;
	//			line-height: 1.4;
	//			text-align: left;
	//
	//			&.selected {
	//				display: block;
	//			}
	//		}
	//	}
	//	.slides {
	//		margin-top: 1.5rem;
	//		overflow: hidden;
	//		background: #fff;
	//
	//		.slider {
	//			white-space: nowrap;
	//			transition: transform 0.3s;
	//
	//			div {
	//				position: relative;
	//				display: inline-block;
	//				width: 100%;
	//				padding-top: 75%;
	//			}
	//		}
	//		img {
	//			position: absolute;
	//			top: 50%;
	//			left: 50%;
	//			display: block;
	//			max-width: 100%;
	//			max-height: 100%;
	//			transform: translateX(-50%) translateY(-50%);
	//		}
	//	}
	//	.controls {
	//		margin-top: 2rem;
	//
	//		a {
	//			display: inline-block;
	//			width: 1.3rem;
	//			height: 1.3rem;
	//			margin: 0 0.9rem;
	//			background: #fff;
	//			border: 0.1rem solid #000;
	//			border-radius: 50%;
	//
	//			&:hover,
	//			&:focus,
	//			&:active {
	//				background: #000;
	//			}
	//			&.selected {
	//				background: #000;
	//			}
	//		}
	//	}
	// }
	// #bonopot {
	//	background: $transparentMagenta;
	//
	//	.btn {
	//		background: $magenta;
	//
	//		&:hover,
	//		&:focus,
	//		&:active {
	//			color: $magenta;
	//		}
	//	}
	// }
	// #posmio {
	//	background: $transparentAquamarine;
	//
	//	.btn {
	//		background: $aquamarine;
	//
	//		&:hover,
	//		&:focus,
	//		&:active {
	//			color: $aquamarine;
	//		}
	//	}
	// }
	//
	// #bonopot-header .spacer {
	//	background: #8a2d6e;
	// }
	// #posmio-header .spacer {
	//	background: #55c7c5;
	// }
	//
	// @media (min-width: emBreakpoint($tabletWidth)) {
	//	section.content {
	//		margin-bottom: 15rem;
	//		padding-bottom: 12rem;
	//
	//		&:last-of-type {
	//			margin-bottom: 5rem;
	//		}
	//		h2 {
	//			margin-top: 10rem;
	//		}
	//	}
	//	.image-gallery {
	//		max-width: $desktopWidth;
	//		margin: 7rem auto 0;
	//		padding: 6rem;
	//		overflow: auto;
	//		background: #fff;
	//
	//		.description {
	//			float: left;
	//			width: 40%;
	//
	//			a {
	//				display: block;
	//				padding-right: 2.5rem;
	//				color: rgba(0, 0, 0, 0.2);
	//				transition: color 0.3s;
	//
	//				&:after {
	//					display: block;
	//					width: 8%;
	//					height: 0.3rem;
	//					margin-top: 0.8rem;
	//					background-color: rgba(0, 0, 0, 0.2);
	//					transition: width 0.3s, background-color 0.3s;
	//					content: "";
	//				}
	//				&:hover,
	//				&:focus,
	//				&:active,
	//				&.selected {
	//					color: #000;
	//
	//					&:after {
	//						width: 24%;
	//						background-color: #000;
	//					}
	//				}
	//			}
	//			a + a {
	//				margin-top: 2rem;
	//			}
	//		}
	//		.slides {
	//			position: relative;
	//			float: right;
	//			width: 60%;
	//			margin-top: 0;
	//		}
	//		.controls {
	//			float: right;
	//			width: 60%;
	//			margin-top: 3rem;
	//		}
	//	}
	//	#bonopot {
	//		h2 img {
	//			width: 36.2rem;
	//		}
	//		.image-gallery .description a {
	//			&:hover,
	//			&:focus,
	//			&:active,
	//			&.selected {
	//				&:after {
	//					background-color: $magenta;
	//				}
	//			}
	//		}
	//	}
	//	#posmio {
	//		h2 img {
	//			width: 34.2rem;
	//		}
	//		.image-gallery .description a {
	//			&:hover,
	//			&:focus,
	//			&:active,
	//			&.selected {
	//				&:after {
	//					background-color: $aquamarine;
	//				}
	//			}
	//		}
	//	}
	// }
	// @media (min-width: emBreakpoint($pageWidth)) {
	//	section.video {
	//		margin-top: -10.1rem;
	//	}
	//	section.content + section.video {
	//		margin-top: -20.2rem;
	//	}
	// }
}
