#server-message {
	margin-top: 0;
	padding: 11vh 1.5rem;

	@media (min-width: emBreakpoint($mobilePlusWidth)) {
		padding-right: 3rem;
		padding-left: 3rem;
	}

	&:after {
		display: none;
	}
	img {
		width: auto;
		height: 32vw;
		max-height: 16rem;
		margin-bottom: 3rem;
	}
	p {
		color: $magenta;
		font-weight: $boldFont;
		font-size: 1.9rem;
		line-height: 1.4;

		strong {
			display: inline-block;
			padding: 0.6rem 0.8rem;
			color: #fff;
			font-weight: $boldFont;
			background: $magenta;
		}
		br {
			display: none;
		}
	}
	.btn {
		margin-top: 3rem;
		background: $magenta;

		&:hover,
		&:focus,
		&:active {
			color: $magenta;
		}
	}

	@media (min-width: emBreakpoint($tabletWidth)) {
		padding-top: 20vh;

		p {
			font-size: 2.4rem;

			br {
				display: block;
			}
		}
		img {
			max-height: 21.4rem;
		}
	}
}
