#home {
	> header {
		z-index: 0;
		margin-top: -4.5rem;
		margin-bottom: -1rem;
		padding: 0;

		/* .spacer {
			background: $magenta;
		} */

		@media (min-width: emBreakpoint($mobilePlusWidth)) {
			margin-top: -5rem;
		}
		@media (min-width: emBreakpoint($tabletWidth)) {
			margin-top: -7rem;
			margin-bottom: -5rem;
		}
		@media (min-width: emBreakpoint($desktopWidth)) {
			margin-top: -13rem;
		}
	}

	// Kdo jsme
	#who-we-are {
		position: relative;
		z-index: 1;
		margin-top: 50%;
		background: $transparentMagenta;
		@include skew();

		> img {
			position: absolute;
			bottom: 100%;
			left: 50%;
			width: 80%;
			max-width: 38rem;
			height: auto;
			transform-origin: left center;
			@include unskew(translateX(-50%));
		}
		@media (min-width: emBreakpoint(47rem)) {
			margin-top: 23.5rem;
		}
		.text {
			padding: 2rem 1.5rem 4rem;
			text-align: center;
			@include unskew();

			@media (min-width: emBreakpoint($mobilePlusWidth)) {
				padding-right: 3rem;
				padding-left: 3rem;
			}

			h1 {
				margin: 0;
				padding: 0;
				color: $magenta;
				font-size: 0;
				line-height: 1.3;

				strong {
					display: block;
					margin-bottom: 1.1rem;
					font-size: 3rem;
				}
				span {
					margin: 0 -0.4rem;
					padding: 0.5rem 0.8rem;
					color: #fff;
					font-size: 1.8rem;
					white-space: nowrap;
					text-transform: none;
					background: $magenta;
				}
				br.desktop {
					display: none;
				}
			}
			p {
				margin-top: 1.4rem;
				font-size: 1.7rem;
				line-height: 1.2;
			}
		}

		@media (min-width: emBreakpoint($tabletWidth)) {
			max-width: $desktopWidth;
			margin: 7rem auto 0;

			&:before,
			&:after {
				position: absolute;
				top: 0;
				display: block;
				width: 200rem;
				height: 100%;
				background: $transparentMagenta;
				content: "";
			}
			&:before {
				right: 100%;
			}
			&:after {
				left: 100%;
			}

			> img {
				right: 39%;
				bottom: 0;
				left: auto;
				width: 62%;
				max-width: none;
				@include unskew();
			}
			.text {
				position: relative;
				top: -2.3rem;
				padding-top: 2rem;
				padding-bottom: 7%;
				text-align: right;

				h1 {
					line-height: 2;
					text-align: right;

					strong {
						margin-bottom: 1.6rem;
						font-size: 5.4rem;
						line-height: 1.2;
						text-transform: none;
					}
					span {
						font-size: 3.2rem;
					}
					br.desktop {
						display: block;
					}
				}
				p {
					font-size: 2.6rem;
				}
			}
		}
		@media (min-width: emBreakpoint($desktopWidth)) {
			.text h1 br.desktop {
				display: none;
			}
		}
	}

	// Sekce s klienty
	#our-clients {
		max-width: $maxTextWidth;
		margin: 13rem auto 0;
		padding: 0 2.5rem;
		background: $transparentAquamarine;
		@include skew();


		.arrow {
			position: absolute;
			top: 50%;
			z-index: 2;
			display: block;
			width: 1.5rem;
			height: 5.7rem;
			text-align: center;
			@include unskew(translateY(-50%));

			&:nth-of-type(1) {
				left: 1rem;

				svg {
					transform: rotate(180deg);
				}
			}
			&:nth-of-type(2) {
				right: 1rem;
			}

			svg {
				display: inline-block;
				width: 1.4rem;
				height: 5.7rem;
				fill: none;
				stroke: $aquamarine;
				stroke-width: 0.1rem;
			}
		}
		@media (min-width: emBreakpoint($mobilePlusWidth)) {
			padding: 0 3.5rem;

			.arrow {
				width: 2.5rem;
			}
		}

		> div {
			max-width: $maxTextWidth;
			margin: auto;
			text-align: center;
			@include unskew();

			&:before {
				display: block;
				width: 16rem;
				margin: auto;
				border-bottom: 0.2rem solid $aquamarine;
				content: "";
			}
			> div {
				display: none;
				width: 100%;
				padding: 8% 1.5rem 5.4rem;

				&.visible {
					display: block;
				}

				@media (min-width: emBreakpoint($mobilePlusWidth)) {
					padding-right: 3rem;
					padding-left: 3rem;
				}
			}
			img {
				position: absolute;
				bottom: calc(100% + 2.6rem);
				left: 50%;
				transform: translateX(-50%);

				&[src*="philip-morris"] {
					width: 15rem;
				}
				&[src*="kooperativa"] {
					width: 14rem;
				}
				&[src*="mcdonald"] {
					width: 8rem;
				}
				&[src*="csob"] {
					width: 10.8rem;
				}
				&[src*="ovb"] {
					width: 7.5rem;
				}
				&[src*="obi"] {
					width: 14.5rem;
				}
			}
			h3 {
				color: $aquamarine;
				font-weight: $extraBoldFont;
				font-size: 1.8rem;
				line-height: 1.4;
				text-transform: uppercase;

				span {
					position: relative;
					display: inline-block;
					padding: 1rem 2.2rem;
					white-space: nowrap;
					background: $aquamarine;

					&:first-of-type {
						text-transform: uppercase;
					}
				}
				span ~ span {
					margin-top: 0.7rem;
				}
			}
			p {
				margin-top: 1.2rem;
				color: #000;
				font-size: 1.6rem;
				line-height: 1.4;

				strong {
					font-weight: $boldFont;
				}
			}
		}
		.btn {
			position: absolute;
			top: 100%;
			left: 50%;
			@include unskew(translateX(-50%) translateY(-50%));
		}

		// Animace
		/* stylelint-disable no-duplicate-selectors */
		> div img {
			transform: translateX(-100%);
			opacity: 0;
		}
		> div > div.visible {
			img {
				transform: translateX(-50%);
				opacity: 1;
				animation-name: slideLogo;
				animation-duration: 0.8s;
				animation-fill-mode: backwards;
			}

			@keyframes slideLogo {
				0% {
					transform: translateX(-100%);
					opacity: 0;
				}
				50% {
					transform: translateX(-42%);
				}
				100% {
					transform: translateX(-50%);
					opacity: 1;
				}
			}
		}
		/* stylelint-enable no-duplicate-selectors */

		@media (min-width: emBreakpoint($tabletWidth)) {
			margin: 24rem auto 0;

			/* &:before, &:after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				height: 100%;
				width: 200rem;
				background: $transparentAquamarine;
			}
			&:before {
				right: 100%;
			}
			&:after {
				left: 100%;
			} */

			> div {
				&:before {
					width: 30rem;
					border-width: 0.3rem;
				}
				> div {
					padding-top: 4.6rem;
					padding-bottom: 8.5rem;
				}
				img {
					bottom: calc(100% + 4.4rem);

					&[src*="philip-morris"] {
						width: 28rem;
					}
					&[src*="kooperativa"] {
						width: 26rem;
					}
					&[src*="mcdonald"] {
						width: 16.5rem;
					}
					&[src*="csob"] {
						width: 19.6rem;
					}
					&[src*="ovb"] {
						width: 14rem;
					}
					&[src*="obi"] {
						width: 28rem;
					}
				}
				h3 {
					font-size: 2.6rem;
				}
			}
		}
	}

	// Sekce s nasimi produkty
	#our-products {
		margin-bottom: -3rem;
		overflow: hidden;
		@include skew();

		> div {
			position: relative;
			max-width: 46rem;
			margin: 10% auto 7rem;
			background: $transparentMagenta;

			&:last-of-type {
				margin-bottom: 3rem;
			}
			&:before,
			&:after {
				position: absolute;
				top: 0;
				z-index: 0;
				display: block;
				width: 200rem;
				height: 100%;
				background: $transparentMagenta;
				content: "";
			}
			&:before {
				right: 100%;
			}
			&:after {
				left: 100%;
			}
		}
		.tablet-preview {
			position: absolute;
			bottom: 0;
			z-index: 1;
			height: auto;
			@include unskew();
		}
		.text {
			position: relative;
			z-index: 2;
			padding: 0 1.5rem;
			text-align: left;
			@include unskew();

			img {
				display: inline-block;
				height: auto;
				margin-bottom: 4%;
			}
			h3 {
				color: #fff;
				font-weight: $extraBoldFont;
				font-size: 0;
				line-height: 2.1;
				text-transform: uppercase;

				span {
					margin-right: -0.9rem;
					padding: 0.4rem 0.9rem;
					font-size: 1.8rem;
					background: $magenta;

					@media (min-width: emBreakpoint($mobilePlusWidth)) {
						font-size: 1.9rem;
					}
				}
				br.desktop {
					display: none;
				}
			}
			p {
				display: none;
			}
			.btn {
				position: absolute;
				top: 100%;
				left: 50%;
				background: $magenta;
				transform: translateX(-50%) translateY(-50%);

				&:hover,
				&:focus,
				&:active {
					color: $magenta;
				}
			}
		}

		#bonopot {
			.tablet-preview {
				left: 49%;
				width: 17.8rem;

				@media (min-width: emBreakpoint($mobilePlusWidth)) {
					width: 50%;
				}
			}
			.text {
				padding-top: 11%;
				padding-bottom: 11%;

				img {
					width: 46%;
				}
			}
		}
		#posmio {
			background: $transparentAquamarine;

			&:before,
			&:after {
				background: $transparentAquamarine;
			}
			.tablet-preview {
				right: 20%;
				bottom: -0.6rem;
				width: 29.8rem;

				@media (min-width: emBreakpoint($mobilePlusWidth)) {
					width: 80%;
				}
			}
			.text {
				padding-top: 6%;
				padding-bottom: 13%;
				text-align: right;

				img {
					width: 42%;
				}
				h3 {
					padding-right: 1rem;

					span {
						background: $aquamarine;
					}
				}
				.btn {
					background: $aquamarine;

					&:hover,
					&:focus,
					&:active {
						color: $aquamarine;
					}
				}
			}
		}

		// Desktopova verze produktu
		@media (min-width: emBreakpoint($tabletWidth)) {
			margin-bottom: -4.5rem;

			> div {
				max-width: 127rem;
				margin-top: 7rem;

				&:last-of-type {
					margin-bottom: 4.5rem;
				}
			}
			.text {
				padding-right: 3rem;
				padding-left: 3rem;

				img {
					margin-bottom: 3.6rem;
				}
				h3 {
					line-height: 1.8;

					span {
						font-size: 3.8rem;
					}
					br.mobile {
						display: none;
					}
					br.desktop {
						display: block;
					}
				}
				p {
					display: block;
					max-width: 46rem;
					margin-top: 1.4rem;
					font-size: 1.6rem;
					line-height: 1.5;
				}
			}
			#bonopot {
				.tablet-preview {
					left: 52%;
					width: 47rem;
				}
				.text {
					padding-top: 17rem;
					padding-bottom: 11rem;
				}
			}
			#posmio {
				.tablet-preview {
					right: 25%;
					bottom: -1.5rem;
					width: 79rem;
				}
				.text {
					padding-top: 9rem;
					padding-bottom: 21rem;

					p {
						margin-left: auto;
					}
				}
			}
		}
		@media (min-width: emBreakpoint($desktopWidth)) {
			> div {
				margin-top: 10rem;
			}
			.text {
				padding-right: 7rem;
				padding-left: 7rem;
			}
			#bonopot {
				.tablet-preview {
					width: 51rem;
				}
			}
			#posmio {
				.tablet-preview {
					right: 32%;
					width: 85rem;
				}
			}
		}
	}
}
