// Promenne a mixins
@import "vars";

// CSS reset
@import "reset";


// Obecne styly
html {
	overflow-x: hidden;
	font-weight: $regularFont;
	font-family: "Open Sans", sans-serif;
}
body {
	overflow-x: hidden;
}
body > header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	pointer-events: none;

	// Tlacitko menu na mobilu
	#show-menu {
		position: absolute;
		z-index: 1;
		width: 100%;
		padding: 2.6rem 1.5rem;
		background-color: rgba(255, 255, 255, 0.9);
		box-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.07);
		transform-origin: top left;
		transition: background-color 0.3s, box-shadow 0.3s;
		pointer-events: all;
		@include skew();
		@include backdrop-filter(blur(0.35rem));

		a {
			position: relative;
			padding-left: 4rem;
			color: #000;
			font-size: 1.6rem;
			transition: color 0.3s;

			&:before {
				position: absolute;
				top: 0.6rem;
				left: 0;
				width: 2.5rem;
				height: 0.3rem;
				border-top: 0.9rem double #000;
				border-bottom: 0.3rem solid #000;
				transition: border-color 0.3s;
				content: "";
			}
		}
	}

	// Mobilni menu
	nav {
		padding-top: 8.6rem;
		padding-bottom: 3.2rem;
		font-size: 0;
		text-transform: lowercase;
		background: $magenta;
		transform: translateY(-100%);

		visibility: hidden;
		transition: transform 0.3s, visibility 0.3s;

		div.left a {
			display: block;
			color: #fff;
			font-size: 2.3rem;
			line-height: 5rem;
			text-align: center;

			&:before,
			&:last-of-type:after {
				display: block;
				width: 17rem;
				height: 0.1rem;
				margin: auto;
				background: rgba(255, 255, 255, 0.3);
				content: "";
			}
			&:hover,
			&:focus,
			&:active,
			&.active {
				background: rgba(255, 255, 255, 0.1);
			}
		}
		div.right {
			margin-top: 3rem;
			text-align: center;

			a {
				display: inline-block;
				width: 5rem;
				height: 5rem;
				color: #fff;
				font-size: 2.3rem;
				line-height: 5rem;

				&:hover,
				&:focus,
				&:active,
				&.active {
					background: rgba(255, 255, 255, 0.1);
				}
			}
		}
	}

	// Zobrazene mobilni menu
	&.show {
		max-height: 100%;
		overflow: auto;
		pointer-events: all;

		#show-menu {
			background-color: rgba(255, 255, 255, 0.1);
			box-shadow: none;
			@include backdrop-filter(none);

			a {
				color: rgba(255, 255, 255, 0.5);

				&:before {
					border-color: #fff;
				}
			}
		}
		nav {
			transform: translateY(0);
			visibility: visible;
			transition: transform 0.3s, visibility 0s;
		}
	}

	// Desktopove menu
	@media (min-width: emBreakpoint($tabletWidth)) {
		background: rgba(255, 255, 255, 0.9);
		box-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.07);
		pointer-events: all;
		@include backdrop-filter(blur(0.35rem));

		#show-menu {
			display: none;
		}
		nav {
			display: block;
			max-width: $pageWidth;
			margin: auto;
			padding: 1.5rem 2rem;
			overflow: auto;
			background: transparent;
			transform: none;
			visibility: visible;
			transition: none;

			div.left a,
			div.right a {
				position: relative;
				display: inline-block;
				width: auto;
				height: auto;
				padding: 0.5rem;
				color: #000;
				font-size: 1.4rem;
				line-height: 2.4rem;

				&:before {
					position: absolute;
					top: 0.4rem;
					left: -0.6rem;
					display: block;
					width: 0;
					height: 0.1rem;
					background: transparent;
					transition: width 0.3s, background 0.3s;
					content: "";
					@include skew();
				}
				&:last-of-type:after {
					display: none;
				}
				&:hover,
				&:focus,
				&:active {
					background: none;

					&:before {
						width: 2.5rem;
						background: #000;
					}
				}
				&.active {
					color: $magenta;

					&:before {
						width: 2.5rem;
						background: $magenta;
					}
				}
				+ a {
					margin-left: 1.8rem;
				}
			}
			div.left {
				float: left;
			}
			div.right {
				float: right;
				margin-top: 0;
			}
		}
	}
}

// Obsah stranky
main {
	display: block;
	margin-top: 6.8rem; // Vyska header
	padding-bottom: 0.1rem;
	text-align: center;
	background: #fff;

	&:after {
		display: block;
		width: 100%;
		height: 5.5rem;
		background: #fff;
		content: "";
		@include skew();
	}
	> header {
		position: relative;
		z-index: 5;
		padding: 1rem 0 4rem;
		transform: none;
		pointer-events: none;

		svg {
			display: block;
			width: 8.7rem;
			height: 6.5rem;
			margin: auto;
		}
		h1,
		h2 {
			margin-top: 1.2rem;
			padding: 0 3rem;
			color: $magenta;
			font-weight: $regularFont;
			font-size: 1.8rem;
			line-height: 1.4;
			text-align: center;
			text-transform: none;

			&:before {
				display: block;
				width: 8.8rem;
				margin: 0 auto 0.5rem;
				border-bottom: 0.2rem solid $magenta;
				content: "";
			}

			strong {
				text-transform: uppercase;
			}
		}
	}
	> header + h1,
	> header + h2 {
		margin-top: 3rem;
	}
	h1,
	h2 {
		position: relative;
		z-index: 3;
		margin: 8rem 0 2rem 0;
		padding: 0 3rem;
		color: $aquamarine;
		font-weight: $extraBoldFont;
		font-size: 2.8rem;
		line-height: 1.4;
		text-align: center;
		text-transform: uppercase;

		&.biggerMargin {
			margin-top: 9rem;
		}
	}
	b {
		font-weight: $boldFont;
	}

	@media (min-width: emBreakpoint($tabletWidth)) {
		> * {
			transform-origin: center;
		}
		&:after {
			z-index: 0;
			height: 20rem;
			margin-top: -19rem;
		}
		> :last-child {
			position: relative;
			z-index: 2;
		}
		> header {
			padding: 4.6rem 0 5rem;

			svg {
				width: 15.3rem;
				height: 11.4rem;
			}
			h1,
			h2 {
				margin-top: 1.8rem;
				font-size: 2.2rem;

				&:before {
					width: 15.4rem;
					margin-bottom: 0.8rem;
					border-width: 0.3rem;
				}
			}
		}
		> header + h1,
		> header + h2 {
			margin-top: 9rem;
		}
		h1,
		h2 {
			margin: 14rem 0 3rem 0;
			font-size: 5.4rem;

			&.biggerMargin {
				margin-top: 16rem;
			}
		}
	}

	.video {
		position: relative;
		max-width: $pageWidth;
		margin-right: auto;
		margin-left: auto;
		transform: none;

		.spacer {
			display: block;
			padding-top: 75%;
		}
		video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			pointer-events: none;
		}
		&:before,
		&:after {
			position: absolute;
			z-index: 1;
			display: block;
			width: 100%;
			height: 19%;
			background: #fff;
			@include skew();
		}
		&:before {
			top: 0;
			transform-origin: top left;
		}
		&:after {
			bottom: 0;
			transform-origin: bottom right;
			content: "";
		}

		@media (min-width: emBreakpoint($tabletWidth)) {
			.spacer {
				padding-top: 56.25%;
			}
			&:before,
			&:after {
				height: 25%;
			}
		}

		// Bugfix IE a Edge (prosvitani videa na krajich)
		body.ie & {
			video {
				border: 0.1rem solid #fff;
				border-bottom-width: 0.2rem;
			}
			.spacer {
				padding-top: calc(75% + 0.2rem);
			}

			@media (min-width: emBreakpoint($tabletWidth)) {
				.spacer {
					padding-top: calc(56.25% + 0.2rem);
				}
			}
		}
	}

	.people-cubes-banner {
		position: absolute;
		top: 0;
		left: 0;
		display: none;
		width: 100%;
		&.cubes-desktop {
			@media (min-width: 1200px) {
				display: inline;
			}
		}
		&.cubes-tablet {
			@media (min-width: 768px) and (max-width: 1199px) {
				display: inline;
			}
		}
		&.cubes-mobile {
			@media (max-width: 767px) {
				display: inline;
			}
		}
	}

	.btn {
		position: relative;
		z-index: 0;
		text-transform: lowercase;
		border: 0.1rem solid transparent;
		cursor: pointer;
		transition: color 0.3s, border-color 0.3s;

		&:hover,
		&:focus,
		&:active {
			border-color: currentColor;

			&:before {
				right: auto;
				left: 0;
				width: 100%;
			}
		}
		&:before {
			position: absolute;
			top: 0;
			right: 0;
			z-index: -1;
			display: block;
			width: 0;
			height: 100%;
			background: #fff;
			transition: width 0.3s;
			content: "";
		}
		&.big {
			display: inline-block;
			padding: 0.8rem 3rem 0.9rem;
			color: #fff;
			font-size: 1.9rem;
			line-height: 1.2;
			white-space: nowrap;
			background: $aquamarine;

			&:hover,
			&:focus,
			&:active {
				color: $aquamarine;
			}

			@media (min-width: emBreakpoint($tabletWidth)) {
				padding: 0.9rem 4.7rem 1.1rem;
				font-size: 2.8rem;
			}
		}
	}

	#join-us {
		margin-top: -4%;
		margin-bottom: -7%;

		.spacer {
			background: #ecebe6;
		}
		.btn {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
		}

		@media (min-width: emBreakpoint($desktopWidth)) {
			.spacer {
				padding-top: 42.85%;
			}
			&:before,
			&:after {
				height: 33%;
			}
		}
		@media (min-width: emBreakpoint($pageWidth)) {
			margin-top: -5.8rem;
			margin-bottom: -10.1rem;
		}
	}

	.image {
		position: relative;
		overflow: hidden;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 124%;
			background-position: center center;
			background-size: cover;
			transform-origin: top right;
			content: "";
			@include unskew();
		}
		&:after {
			display: block;
			padding-bottom: 65%;
			content: "";
		}

		@media (min-width: emBreakpoint($tabletWidth)) {
			&:after {
				padding-bottom: 44%;
			}
			&:before {
				height: 134%;
			}
		}
	}
}

// Slider s lidmi
.people-slider {
	position: relative;
	height: 40rem;
	margin-top: 4.5rem;
	font-size: 1.6rem;
	text-align: left;
	background: $transparentAquamarine;
	@include skew();

	.arrow {
		position: absolute;
		top: 50%;
		z-index: 2;
		display: block;
		width: 2.5rem;
		height: 5.7rem;
		text-align: center;
		@include unskew(translateY(-50%));

		&:first-of-type {
			left: 1rem;

			svg {
				transform: rotate(180deg);
			}
		}
		&:last-of-type {
			right: 1rem;
		}

		svg {
			display: inline-block;
			width: 1.4rem;
			height: 5.7rem;
			fill: none;
			stroke: $magenta;
			stroke-width: 0.1rem;
		}
	}
	> div {
		position: absolute;
		bottom: 0;
		left: 3.5rem;
		width: calc(100% - 7rem);
		height: 100%;
		@include unskew();

		> div {
			position: absolute;
			width: 100%;
			height: 100%;
			visibility: hidden;

			&.visible {
				visibility: visible;
			}

			> div {
				position: absolute;
				top: 17rem;
				left: 50%;
				width: 100%;
				max-width: 30rem;
				text-align: center;
				transform: translateX(-50%);
			}
		}
		h3 {
			margin-bottom: 1.4rem;
			color: #fff;
			font-weight: $extraBoldFont;
			font-size: 2.3rem;
			text-align: center;
			text-transform: uppercase;

			span {
				position: relative;
				display: inline-block;
				padding: 1rem 2.2rem;
				white-space: nowrap;
				background: $aquamarine;
			}
			span ~ span {
				margin-top: 0.7rem;
			}
		}
		p {
			position: relative;
			display: inline;
			color: $magenta;
			font-size: 1.6rem;
			line-height: 1.4;

			strong {
				font-weight: $boldFont;
			}
		}
		img {
			position: absolute;
			bottom: calc(100% - 25rem);
			left: 50%;
			transform: translateX(-50%);
			transform-origin: left bottom;
		}
	}
	@media (min-width: emBreakpoint($mobilePlusWidth)) {
		.arrow {
			&:first-of-type {
				left: 2.5rem;
			}
			&:last-of-type {
				right: 2.5rem;
			}
		}
		> div {
			left: 5rem;
			width: calc(100% - 10rem);
		}
	}

	#slide-m_04 {
		img {
			bottom: calc(100% - 28rem);
			width: 21rem;
		}
		> div {
			top: 18.5rem;
		}
		h3 {
			span:first-of-type {
				left: 2rem;
			}
			span:last-of-type {
				left: -1rem;
			}
		}
		p {
			left: 0.3rem;
		}
	}
	#slide-f_02 {
		img {
			bottom: calc(100% - 20.3rem);
			width: 24rem;
		}
		h3 {
			span:first-of-type {
				left: 0;
			}
			span:last-of-type {
				left: -1rem;
			}
		}
		p {
			left: 0.4rem;
		}
	}
	#slide-m_05 {
		img {
			bottom: calc(100% - 27.2rem);
			width: 22.5rem;
		}
		> div {
			top: 18.5rem;
		}
		h3 {
			span:first-of-type {
				left: 1rem;
			}
			span:last-of-type {
				left: -0.8rem;
			}
		}
		p {
			left: -0.2rem;
		}
	}
	#slide-m_01 {
		img {
			width: 22rem;
		}
	}
	#slide-f_01 {
		img {
			bottom: calc(100% - 20.3rem);
			width: 24rem;
		}
	}
	#slide-m_02 {
		img {
			bottom: calc(100% - 25.5rem);
			width: 23rem;
		}
	}
	#slide-m_03 {
		img {
			bottom: calc(100% - 26rem);
			width: 27.5rem;
		}
	}

	// Animace
	h3 span {
		transform: translateX(-50%);
		opacity: 0;
	}
	div.visible {
		h3 span {
			transform: translateX(0);
			opacity: 1;
			animation-name: slideText;
			animation-duration: 0.8s;
			animation-fill-mode: backwards;
		}

		@keyframes slideText {
			0% {
				transform: translateX(-50%);
				opacity: 0;
			}
			50% {
				transform: translateX(8%);
			}
			100% {
				transform: translateX(0);
				opacity: 1;
			}
		}
	}

	@media (min-width: emBreakpoint($tabletWidth)) {
		max-width: $desktopWidth;
		height: 46rem;
		margin: 5rem auto 0;

		&:before,
		&:after {
			position: absolute;
			top: 0;
			display: block;
			width: 200rem;
			height: 100%;
			background: $transparentAquamarine;
			content: "";
		}
		&:before {
			right: 100%;
		}
		&:after {
			left: 100%;
		}

		.arrow {
			width: 3rem;
			height: 7.6rem;

			&:first-of-type {
				left: 1rem;
			}
			&:last-of-type {
				right: 1rem;
			}

			svg {
				width: 1.9rem;
				height: 7.6rem;
			}
		}
		> div {
			left: 4rem;
			width: calc(100% - 8rem);
			transform: none;

			> div {
				> div {
					top: 22%;
					left: 50%;
					width: 50%;
					max-width: none;
					text-align: left;
					@include unskew();
				}
			}
			img {
				@include unskew();
				bottom: 0 !important;
				left: auto;
			}
			h3 {
				margin-bottom: 2.4rem;
				font-size: 4rem;
				text-align: left;

				span {
					padding: 1rem 2.8rem;
				}
				span ~ span {
					margin-top: 1.5rem;
				}
			}
			p {
				display: block;
				margin-top: 0.7rem;
				font-size: 2.2rem;

				span {
					position: relative;
					display: block;
				}
			}
		}
		#slide-m_04 {
			img {
				right: calc(50% - 1rem);
				width: 35rem;
			}
			> div {
				top: 41%;
			}
			h3 {
				span:first-of-type {
					left: 1rem;
				}
				span:last-of-type {
					left: -5rem;
				}
			}
			p {
				left: 4.5rem;
			}
		}
		#slide-f_02 {
			img {
				right: calc(50% - 11rem);
				width: 49rem;
			}
			> div {
				top: 44%;
			}
			h3 {
				span:first-of-type {
					left: -3rem;
				}
				span:last-of-type {
					left: -6rem;
				}
			}
			p {
				left: 5rem;
			}
		}
		#slide-m_05 {
			img {
				right: calc(50% - 4rem);
				width: 39rem;
			}
			> div {
				top: 43%;
			}
			h3 {
				span:first-of-type {
					left: -6rem;
				}
				span:last-of-type {
					left: -1rem;
				}
			}
			p {
				left: 8rem;
			}
		}
		#slide-m_01 {
			img {
				right: 45%;
				width: 49rem;
			}
			h3 {
				span:first-of-type {
					left: 0.6rem;
				}
				span:last-of-type {
					left: 3rem;
				}
			}
			p {
				left: 6rem;
			}
		}
		#slide-f_01 {
			img {
				right: 45%;
				width: 49rem;
			}
			> div {
				left: 56%;
			}
			h3 {
				span:first-of-type {
					left: -1.4rem;
				}
				span:last-of-type {
					left: -4rem;
				}
			}
			p {
				left: 1rem;
			}
		}
		#slide-m_02 {
			img {
				right: 47%;
				width: 42rem;
			}
			> div {
				top: 25%;
			}
			h3 {
				span:first-of-type {
					left: 8.8rem;
				}
				span:last-of-type {
					left: 3.4rem;
				}
			}
			p {
				left: 6rem;
			}
		}
		#slide-m_03 {
			img {
				right: 48%;
				width: 50rem;
			}
			h3 {
				span:first-of-type {
					left: -2rem;
				}
				span:last-of-type {
					left: 1rem;
				}
			}
			p {
				left: 4rem;
			}
		}
	}
}

.spacer-footer {
	padding-bottom: 6rem;

	@media (min-width: emBreakpoint($tabletWidth)) {
		padding-bottom: 14rem;
	}
}

// Paticka
body > footer {
	color: $black;
	font-size: 0;
	background: #f4deec;
	@media (min-width: emBreakpoint($tabletWidth)) {
		margin-top: -0.8rem;
		padding-bottom: 0.8rem;
	}
	// Sklon paticky nahore
	&:before {
		position: relative;
		top: -0.2rem;
		z-index: 2;
		display: block;
		width: 100%;
		height: 20rem;
		margin-bottom: -18rem;
		background: #f4deec;
		content: "";
		@include skew();

		// @media (min-width: emBreakpoint($tabletWidth)) {
		// 	margin-bottom: -18.8rem;
		// }
	}

	// Vnitrek paticky
	> * {
		position: relative;
		z-index: 2;
		max-width: 96rem;
		margin: auto;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}
	@media (min-width: emBreakpoint($mobilePlusWidth)) {
		> * {
			padding-right: 3rem;
			padding-left: 3rem;
		}
	}

	// Logo v paticce
	#footer-logo {
		margin-bottom: 2rem;
		font-size: 3rem;
		text-align: right;

		svg {
			display: inline-block;
			width: 6.2rem;
			height: 4.6rem;
		}
	}

	// Adresa a kontakt
	address {
		display: inline-block;
		font-size: 1.6rem;
		line-height: 1.8rem;
		white-space: nowrap;
		vertical-align: top;
		.social-icons {
			display: flex;
			margin-top: 3rem;
			.social-icon-link {
				margin-left: 1rem;
			}
			@media (max-width: 768px) {
				justify-content: center;
				.social-icon-link:first-child {
					margin-left: 0;
				}
			}
		}

		a {
			color: inherit;

			&:hover,
			&:focus,
			&:active {
				text-decoration: underline;
			}
		}
		> span {
			display: block;
		}

		&:first-of-type {
			width: 55%;
			margin-right: 1.5rem;
		}
	}
	h5 {
		margin-bottom: 1.4rem;
		color: $magenta;
		font-weight: $boldFont;
		font-size: 2.5rem;
	}

	// Text v paticce
	#footer-claim {
		font-size: 1.6rem;
		line-height: 1.8rem;
		vertical-align: top;

		p {
			margin-bottom: 1.5rem;
		}

		form {
			width: 100%;

			.question-input {
				width: 100%;
				margin-bottom: 1rem;
				padding: 0.8rem 1.5rem;

				&:invalid {
					box-shadow: none;
				}

				&.error {
					box-shadow: 0 0 0.2rem 0.1rem red;
				}

				&.input__message {
					height: 8rem;
					resize: none;
				}
			}

			textarea.question-input.input__message {
				margin-bottom: 1rem;
			}

			.consent-and-send {
				display: flex;
				flex-flow: column nowrap;
				align-items: flex-start;
				margin-top: -0.3rem;

				.submit-message-btn {
					align-self: center;
					padding: 0.8rem 4rem;
					color: $white;
					font-size: 1.7rem;
					background-color: $magenta;
					border: none;
					border-radius: 5px;
					cursor: pointer;
				}

				.consent {
					margin-bottom: 2rem;
				}
			}

			.consent-label {
				position: relative;
				display: block;
				padding-left: 28px;
				cursor: pointer;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;

				a {
					color: $magenta;
					text-decoration: underline;

					&:hover {
						text-decoration: none;
					}
				}

				// &:hover input ~ .checkmark,
				// &:hover input:checked ~ .checkmark {
				//	background-color: $magenta;
				// }
				//
				// &:hover input:checked ~ .checkmark {
				//	border-color: #000000;
				// }

				input:checked ~ .checkmark {
					background-color: $magenta;
					border: 1px solid $magenta;

					&:after {
						display: block;
					}
				}

				.checkmark:after {
					top: 2px;
					left: 5px;
					width: 4px;
					height: 8px;
					border: solid white;
					border-width: 0 2px 2px 0;
					-webkit-transform: rotate(45deg);
					-ms-transform: rotate(45deg);
					transform: rotate(45deg);
				}

				&.error .checkmark {
					box-shadow: 0 0 0.2rem 0.1rem red;
				}
			}

			.input[type="checkbox"] {
				position: absolute;
				width: 0;
				height: 0;
				cursor: pointer;
				opacity: 0;
			}

			.checkmark {
				position: absolute;
				top: 0;
				left: 0;
				width: 18px;
				height: 18px;
				background-color: white;
				border: 1px solid $magenta;

				&:after {
					position: absolute;
					display: none;
					content: "";
				}
			}
		}

		.input-message {
			display: block !important;
			margin-top: 0.5rem;
			margin-bottom: 1rem;
			color: $aquamarine;
			font-weight: $boldFont;
			font-size: 1.4rem;
			line-height: 1.4;
			text-align: left;

			&.error {
				color: red;
			}
		}

		input + .input-message {
			margin-top: -0.5rem;
		}

		textarea + .input-message {
			margin-top: -0.7rem;
			margin-bottom: 1.3rem;
		}

		.message {
			position: relative;
			width: 100%;
			max-height: 10rem;
			margin-top: 1rem;
			margin-bottom: 4rem;
			padding: 2rem 2rem 2rem 6rem;
			overflow: hidden;
			color: #fff;
			font-weight: $boldFont;
			font-size: 1.6rem;
			line-height: 1.4;
			text-align: left;
			background: $aquamarine;
			transition: all 0.3s 0.3s;

			&:after {
				position: absolute;
				top: 50%;
				left: 1.5rem;
				display: block;
				box-sizing: border-box;
				width: 3rem;
				height: 3rem;
				text-align: center;
				background: url(../img/checkbox-check.svg) center center no-repeat;
				background-size: 2.4rem 1.8rem;
				transform: translateY(-50%);
				content: "";
				pointer-events: none;
			}
			&.error {
				background: red;

				&:after {
					font-size: 3.2rem;
					line-height: 3rem;
					background: transparent;
					content: "!";
				}
			}
			&:empty {
				max-height: 0;
				margin-bottom: 0;
				padding-top: 0;
				padding-bottom: 0;
				opacity: 0;
			}
		}
	}

	.footer-flex {
		display: flex;
		background-color: #f4deec;

		@media (max-width: 48em) {
			flex-flow: column nowrap;

			address {
				order: 2;
				margin: 0 auto;
				text-align: center;
			}

			#footer-claim {
				order: 1;
				margin-bottom: 4rem;
			}
		}
	}

	// Copyright
	small {
		display: block;
		padding-top: 4rem;
		padding-bottom: 1.5rem;
		font-size: 1.4rem;
		text-align: center;
	}

	// Desktopova paticka
	@media (min-width: emBreakpoint($tabletWidth)) {
		#footer-logo {
			margin-bottom: 6rem;

			svg {
				width: 12.4rem;
				height: 9.2rem;
			}
		}
		address {
			width: 30% !important;
		}
		#footer-claim {
			display: inline-block;
		}
		small {
			padding-top: 5rem;
			padding-bottom: 4rem;
			text-align: right;
		}
	}
}


// Stranky
@import "home";
@import "clients";
@import "about";
@import "products";
@import "career";
@import "contact";
@import "error";
@import "server-error";
@import "services";
@import "video";
