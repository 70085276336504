#services {
	.anchor-id {
		position: relative;
		top: -77rem;
		display: block;
		visibility: hidden;

		@media (max-width: emBreakpoint($desktopWidth)) {
			top: -50rem;
		}

		@media (max-width: emBreakpoint($tabletWidth)) {
			top: -40rem;
		}

		@media (max-width: emBreakpoint($mobilePlusWidth)) {
			top: -30rem;
		}
	}

	#all-services {
		position: relative;
		z-index: 3;
		display: none;
		margin-bottom: 3rem;
		text-align: center;
		@include skew();

		@media (min-width: emBreakpoint($tabletWidth)) {
			display: block;
		}

		.row {
			@media (min-width: emBreakpoint($tabletWidth)) {
				@include flexbox();
				position: relative;
				max-width: $pageWidth;
				margin: auto;
			}

			@media (min-width: emBreakpoint($desktopWidth)) {
				padding: 0 6rem;
			}
		}

		.row > div {
			position: relative;
			padding: 4rem 1.5rem 0 1.5rem;

			@media (min-width: emBreakpoint($tabletWidth)) {
				@include flexbox();
				position: relative;
				width: 33.3%;
				padding: 5rem 1% 0 1%;
			}

			@media (min-width: emBreakpoint($desktopWidth)) {
				padding-right: 2rem;
				padding-left: 2rem;
			}

			div.text {
				width: 100%;
				height: 9.5rem;
				margin-top: 0 !important;
				padding-top: 1.5rem;
				padding-bottom: 3.5rem;
				background: $transparentAquamarine;

				@media (min-width: emBreakpoint($tabletWidth)) {
					padding-top: 0;
					padding-bottom: 1.3rem;
				}
			}

			div.wrapper {
				padding: 0;

				@media (min-width: emBreakpoint($tabletWidth)) {
					height: auto !important;
				}
			}

			.text {
				a {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 13rem;
					height: 5.1rem;
					margin-top: 1rem;
					margin-left: 1.5rem;
					color: #4d4d4d;
					font-weight: $extraBoldFont;
					font-size: 1.2rem;
					line-height: 1.7rem;
					text-transform: uppercase;
					@include unskew();

					@media (min-width: emBreakpoint($mobilePlusWidth)) {
						width: 15rem;
						height: 5.1rem;
						margin-left: 2rem;
					}

					@media (min-width: emBreakpoint($mobileBigWidth)) {
						width: 17rem;
						margin-left: 3rem;
						font-size: 1.4rem;
						line-height: 1.9rem;
					}

					@media (min-width: emBreakpoint($tabletWidth)) {
						width: 15rem;
						margin-top: 2.5rem;
						margin-left: 1rem;
						font-size: 1.2rem;
						line-height: 1.7rem;
					}

					@media (min-width: emBreakpoint($tabletBigWidth)) {
						width: 18rem;
						margin-left: 2rem;
						font-size: 1.4rem;
						line-height: 1.9rem;
					}

					@media (min-width: emBreakpoint($desktopWidth)) {
						margin-left: 1rem;
					}

					@media (min-width: emBreakpoint($pageWidth)) {
						width: 20rem;
						height: 6.6rem;
						margin-top: 1.5rem;
						margin-left: 3rem;
						font-size: 1.6rem;
						line-height: 2.2rem;
					}

					&:hover:before {
						width: 1rem;
					}

					&:before {
						position: absolute;
						bottom: -1.5rem;
						left: 50%;
						display: block;
						width: 100%;
						border-bottom: 0.2rem solid $aquamarine;
						transform: translateX(-50%);
						transition: width 0.5s;
						content: "";

						@media (min-width: emBreakpoint($mobileBigWidth)) {
							bottom: -2rem;
						}

						@media (min-width: emBreakpoint($desktopWidth)) {
							bottom: -1.5rem;
						}
					}
				}
			}

			img {
				position: absolute;
				right: 1rem;
				bottom: 0;
				width: 15.5rem;
				margin-top: 0;
				@include unskew();

				@media (min-width: emBreakpoint($mobilePlusWidth)) {
					bottom: -2.5rem;
					width: 17rem;
					margin-top: 0;
				}

				@media (min-width: emBreakpoint($tabletWidth)) {
					right: 0;
					bottom: 0;
					width: 11rem;
					margin-top: 0;
				}

				@media (min-width: emBreakpoint($tabletBigWidth)) {
					right: 0;
					bottom: -2rem;
					width: 16rem;
					margin-top: 0;
				}

				@media (min-width: emBreakpoint($desktopWidth)) {
					right: 0;
					bottom: -2rem;
					width: 14rem;
					margin-top: 0;
				}

				@media (min-width: emBreakpoint($pageWidth)) {
					bottom: -2.5rem;
					width: 17rem;
				}
			}
		}

		// Fialova barva
		.row:nth-of-type(odd) > div:nth-of-type(even),
		.row:nth-of-type(even) > div:nth-of-type(odd) {
			div.text {
				background: $transparentMagenta;

				a {
					&:before {
						border-color: $magenta;
					}
				}
			}
		}
	}

	#all-services-details {
		position: relative;
		z-index: 3;
		margin-bottom: 5rem;
		text-align: center;

		@media (min-width: emBreakpoint($tabletWidth)) {
			margin-bottom: 10rem;
		}

		.anchor-id {
			position: relative;
			top: -12rem;
			display: block;
			visibility: hidden;

			@media (max-width: emBreakpoint($desktopWidth)) {
				top: -11rem;
			}

			@media (max-width: emBreakpoint($tabletWidth)) {
				top: -40rem;
			}

			@media (max-width: emBreakpoint($mobilePlusWidth)) {
				top: -30rem;
			}
		}

		.row {
			max-width: $pageWidth;
			margin: auto;

			@media (min-width: emBreakpoint($desktopWidth)) {
				padding: 0 8rem;
			}
			&:nth-of-type(even) .text {
				text-align: left;
				a {
					&:before {
						border-bottom: 0.2rem solid $magenta;
					}
					color: $magenta;
					.arrow-right {
						border: solid $magenta;
					}
				}
			}
			&:nth-of-type(odd) .text {
				a {
					&:before {
						border-bottom: 0.2rem solid $aquamarine;
					}
					color: $aquamarine;
					.arrow-right {
						border: solid $aquamarine;
					}
				}
			}
		}

		.row > div {
			position: relative;
			width: 100%;
			margin: 17rem 0 0 0;

			@media (min-width: emBreakpoint($mobileBigWidth)) {
				margin: 20rem 0 0 0;
			}

			@media (min-width: emBreakpoint($tabletWidth)) {
				display: flex;
				justify-content: flex-end;
				height: 31rem;
				margin: 5rem 0 0 0;
			}

			@media (min-width: emBreakpoint($tabletBigWidth)) {
				margin: 7rem 0 0 0;
			}

			@media (min-width: emBreakpoint($desktopWidth)) {
				height: 30rem;
			}

			@media (min-width: emBreakpoint($pageWidth)) {
				height: 34rem;
				margin: 7rem 0 0 0;
			}

			&:after {
				position: absolute;
				top: 12%;
				right: 0;
				z-index: -1;
				width: 100%;
				height: 100%;
				background: $transparentAquamarine;
				content: "";
				@include skew();
			}

			div.text {
				width: 100%;
				padding: 8rem 1.5rem 1rem 1.5rem;

				@media (min-width: emBreakpoint($mobileBigWidth)) {
					padding: 8rem 1.5rem 3rem 1.5rem;
				}

				@media (min-width: emBreakpoint($tabletWidth)) {
					width: 60%;
					padding: 2rem 3.5rem 2rem 3.5rem;
					text-align: right;
				}

				@media (min-width: emBreakpoint($tabletBigWidth)) {
					position: static;
					padding: 1rem 3.5rem 2rem 3.5rem;
				}

				@media (min-width: emBreakpoint($desktopWidth)) {
					position: absolute;
					top: 50%;
					padding: 4rem 3.5rem 0 1.5rem;
					transform: translateY(-65%);
				}

				@media (min-width: 1200px) {
					transform: translateY(-70%);
				}

				@media (min-width: emBreakpoint($pageWidth)) {
					padding: 0 3.5rem 0 1.5rem;
					transform: translateY(-60%);
				}
			}

			h2 {
				position: relative;
				display: block;
				width: 100%;
				margin-top: 0;
				margin-bottom: 0.4rem;
				padding: 0;
				color: #fff;
				font-weight: $extraBoldFont;
				font-size: 1.8rem;
				line-height: 2.8rem;
				text-transform: none;

				@media (min-width: emBreakpoint($mobilePlusWidth)) {
					font-size: 2.1rem;
					line-height: 3.4rem;
				}

				@media (min-width: emBreakpoint($tabletWidth)) {
					text-align: right;
				}

				@media (min-width: emBreakpoint($tabletBigWidth)) {
					font-size: 3.1rem;
					line-height: 4.6rem;
				}

				@media (min-width: emBreakpoint($pageWidth)) {
					font-size: 4.1rem;
					line-height: 6.1rem;
				}

				span {
					padding: 0 1rem;
					background: $aquamarine;
				}
			}

			p {
				color: $gray;
				font-size: 1.6rem;
				line-height: 2.4rem;

				@media (min-width: emBreakpoint($desktopWidth)) {
					font-size: 1.8rem;
					line-height: 2.4rem;
				}

				@media (min-width: emBreakpoint($pageWidth)) {
					max-width: 60rem;
					margin-top: 1rem;
					margin-left: auto;
				}

				&.description-short {
					@media (min-width: emBreakpoint($tabletWidth)) {
						max-width: 33rem;
					}

					@media (min-width: emBreakpoint($tabletBigWidth)) {
						max-width: 50rem;
					}

					@media (min-width: emBreakpoint($pageWidth)) {
						max-width: none;
					}
				}
			}
			&.service {
				.text {
					a {
						position: relative;
						font-weight: 700;
						font-size: 1.8rem;
						line-height: 2.4rem;

						// Podtržítko s hover effectem
						&:before {
							position: absolute;
							bottom: -0.5rem;
							left: 50%;
							display: block;
							width: 100%;
							transform: translateX(-50%);
							transition: width 0.5s;
							content: "";
						}

						&:hover,
						&:focus {
							&:before {
								width: 1rem;
							}

							.arrow-right {
								transform: rotate(-45deg) translate(0.1rem, 0.1rem);
							}
						}

						// Šipka doprava u odkazu
						.arrow-right {
							display: inline-block;
							padding: 0.5rem;
							border-width: 0 0.3rem 0.3rem 0;
							transform: rotate(-45deg);
							transition: all 0.5s;
						}
					}
					p {
						margin-bottom: 1rem;
					}
				}
			}
			.wrapper {
				margin-bottom: 1.5rem;
			}
		}

		.img-wrapper {
			position: absolute;
			top: -11rem;
			left: 50%;
			width: 80%;
			height: 23rem;
			margin: auto;
			transform: translateX(-50%);

			@media (min-width: emBreakpoint($mobileBigWidth)) {
				top: -20rem;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		.service-development {
			.img-wrapper {
				top: -10rem;
				width: 60%;
				height: 17rem;
			}

			@media (min-width: emBreakpoint($mobileBigWidth)) {
				.img-wrapper {
					top: -13rem;
					width: 70%;
					height: 20rem;
				}
			}

			@media (min-width: emBreakpoint($tabletWidth)) {
				.img-wrapper {
					top: inherit;
					bottom: -5rem;
					left: 0;
					width: 40rem;
					height: 29rem;
					transform: translateX(0);
				}
			}

			@media (min-width: emBreakpoint($pageWidth)) {
				.img-wrapper {
					bottom: -8rem;
					left: 4rem;
					width: 41rem;
					height: 33rem;
				}
			}
		}
		.service-digitization {
			.img-wrapper {
				top: -10.5rem;
				width: 70%;
				height: 19rem;
			}

			@media (min-width: emBreakpoint($mobileBigWidth)) {
				.img-wrapper {
					top: -14rem;
					width: 80%;
					height: 21rem;
				}
			}

			@media (min-width: emBreakpoint($tabletWidth)) {
				.img-wrapper {
					top: inherit;
					right: 1rem;
					bottom: 0;
					left: inherit;
					width: 34rem;
					height: 29rem;
					transform: translateX(0);
				}
			}

			@media (min-width: emBreakpoint($desktopWidth)) {
				.img-wrapper {
					bottom: 3rem;
				}
			}

			@media (min-width: emBreakpoint($pageWidth)) {
				.img-wrapper {
					right: 2rem;
					bottom: 3rem;
					width: 48rem;
					height: 31rem;
				}
			}
		}
		.service-digital {
			.img-wrapper {
				top: -10rem;
				width: 74%;
				height: 19rem;
			}

			@media (min-width: emBreakpoint($mobileBigWidth)) {
				.img-wrapper {
					top: -13rem;
					width: 80%;
					height: 21rem;
				}
			}

			@media (min-width: emBreakpoint($tabletWidth)) {
				.img-wrapper {
					top: inherit;
					bottom: -6rem;
					left: 0;
					width: 38rem;
					height: 29rem;
					transform: translateX(0);
				}
			}

			@media (min-width: emBreakpoint($pageWidth)) {
				.img-wrapper {
					bottom: -9rem;
					width: 54rem;
					height: 32rem;
				}
			}
		}
		.service-analytics {
			.img-wrapper {
				top: -11rem;
				width: 74%;
				height: 19rem;
			}

			@media (min-width: emBreakpoint($mobileBigWidth)) {
				.img-wrapper {
					top: -13rem;
					width: 80%;
					height: 21rem;
				}
			}

			@media (min-width: emBreakpoint($tabletWidth)) {
				.img-wrapper {
					top: inherit;
					right: 0;
					bottom: 0;
					left: inherit;
					width: 33rem;
					height: 29rem;
					transform: translateX(0);
				}
			}

			@media (min-width: emBreakpoint($desktopWidth)) {
				.img-wrapper {
					bottom: 2rem;
				}
			}

			@media (min-width: emBreakpoint($pageWidth)) {
				.img-wrapper {
					bottom: -2.5rem;
					width: 50rem;
					height: 36rem;
				}
			}
		}
		.service-helpdesk {
			.img-wrapper {
				top: -9rem;
				width: 60%;
				height: 17rem;
			}

			@media (min-width: emBreakpoint($mobileBigWidth)) {
				.img-wrapper {
					top: -13rem;
					width: 70%;
					height: 20rem;
				}
			}

			@media (min-width: emBreakpoint($tabletWidth)) {
				.img-wrapper {
					top: inherit;
					bottom: -7rem;
					left: 0;
					width: 40rem;
					height: 29rem;
					transform: translateX(0);
				}
			}

			@media (min-width: emBreakpoint($pageWidth)) {
				.img-wrapper {
					bottom: -9rem;
					left: 3rem;
					width: 45rem;
					height: 33rem;
				}
			}
		}
		.service-management {
			.img-wrapper {
				top: -10rem;
				width: 74%;
				height: 19rem;
			}

			@media (min-width: emBreakpoint($mobileBigWidth)) {
				.img-wrapper {
					top: -14rem;
					width: 80%;
					height: 21rem;
				}
			}

			@media (min-width: emBreakpoint($tabletWidth)) {
				.img-wrapper {
					top: inherit;
					right: 1rem;
					bottom: 0;
					left: inherit;
					width: 34rem;
					height: 29rem;
					transform: translateX(0);
				}
			}

			@media (min-width: emBreakpoint($desktopWidth)) {
				.img-wrapper {
					bottom: 3rem;
				}
			}

			@media (min-width: emBreakpoint($pageWidth)) {
				.img-wrapper {
					right: 2rem;
					bottom: -1rem;
					width: 61rem;
					height: 38rem;
				}
			}
		}
		.service-monitoring {
			.img-wrapper {
				top: -9rem;
				width: 74%;
				height: 19rem;
			}

			@media (min-width: emBreakpoint($mobileBigWidth)) {
				.img-wrapper {
					top: -12.5rem;
					width: 80%;
					height: 21rem;
				}
			}

			@media (min-width: emBreakpoint($tabletWidth)) {
				.img-wrapper {
					top: inherit;
					bottom: -10rem;
					left: 0;
					width: 38rem;
					height: 29rem;
					transform: translateX(0);
				}
			}

			@media (min-width: emBreakpoint($pageWidth)) {
				.img-wrapper {
					bottom: -8rem;
					left: 1rem;
					width: 56rem;
					height: 34rem;
				}
			}
		}
		.service-graphic_design {
			.img-wrapper {
				top: -10rem;
				width: 74%;
				height: 19rem;
			}

			@media (min-width: emBreakpoint($mobileBigWidth)) {
				.img-wrapper {
					top: -13rem;
					width: 80%;
					height: 21rem;
				}
			}

			@media (min-width: emBreakpoint($tabletWidth)) {
				.img-wrapper {
					top: inherit;
					right: 1rem;
					bottom: -4rem;
					left: inherit;
					width: 34rem;
					height: 29rem;
					transform: translateX(0);
				}
			}

			@media (min-width: emBreakpoint($desktopWidth)) {
				.img-wrapper {
					bottom: 1rem;
				}
			}

			@media (min-width: emBreakpoint($pageWidth)) {
				.img-wrapper {
					right: 5rem;
					bottom: -4rem;
					width: 53rem;
					height: 38rem;
				}
			}
		}
		.service-motion_design {
			.img-wrapper {
				top: -10.5rem;
				width: 74%;
				height: 19rem;
			}

			@media (min-width: emBreakpoint($mobileBigWidth)) {
				.img-wrapper {
					top: -14rem;
					width: 80%;
					height: 21rem;
				}
			}

			@media (min-width: emBreakpoint($tabletWidth)) {
				.img-wrapper {
					top: inherit;
					bottom: -6rem;
					left: 0.5rem;
					width: 40rem;
					height: 29rem;
					transform: translateX(0);
				}
			}

			@media (min-width: emBreakpoint($pageWidth)) {
				.img-wrapper {
					bottom: -7rem;
					left: 2rem;
					width: 50rem;
					height: 32rem;
				}
			}
		}
		.service-design_ux {
			.img-wrapper {
				top: -9rem;
				width: 74%;
				height: 19rem;
			}

			@media (min-width: emBreakpoint($mobileBigWidth)) {
				.img-wrapper {
					top: -10.5rem;
					width: 70%;
					height: 18rem;
				}
			}

			@media (min-width: emBreakpoint($tabletWidth)) {
				.img-wrapper {
					top: inherit;
					right: 1rem;
					bottom: -2rem;
					left: inherit;
					width: 34rem;
					height: 29rem;
					transform: translateX(0);
				}
			}

			@media (min-width: emBreakpoint($desktopWidth)) {
				.img-wrapper {
					bottom: 2.5rem;
				}
			}

			@media (min-width: emBreakpoint($pageWidth)) {
				.img-wrapper {
					right: 4rem;
					bottom: 0;
					width: 60rem;
					height: 32rem;
				}
			}
		}
		.service-data_integration {
			.img-wrapper {
				top: -12rem;
				width: 74%;
				height: 19rem;
			}

			@media (min-width: emBreakpoint($mobileBigWidth)) {
				.img-wrapper {
					top: -14rem;
					width: 80%;
					height: 21rem;
				}
			}

			@media (min-width: emBreakpoint($tabletWidth)) {
				.img-wrapper {
					top: inherit;
					bottom: -6rem;
					left: 0;
					width: 35rem;
					height: 29rem;
					transform: translateX(0);
				}
			}

			@media (min-width: emBreakpoint($pageWidth)) {
				.img-wrapper {
					bottom: -8rem;
					left: 2rem;
					width: 54rem;
					height: 38rem;
				}
			}
		}

		// Fialova barva
		.row:nth-of-type(odd) > div:nth-of-type(even),
		.row:nth-of-type(even) > div:nth-of-type(odd) {
			&:after {
				background: $transparentMagenta;
			}

			@media (min-width: emBreakpoint($tabletWidth)) {
				justify-content: flex-start;
			}

			// .img-wrapper {
			//	@media (min-width: emBreakpoint($desktopWidth)) {
			//		right: 0;
			//		left: inherit;
			//	}
			// }

			div.text {
				@media (min-width: emBreakpoint($tabletWidth)) {
					margin-top: 6%;
					padding: 5.5rem 3.5rem 0 3.5rem;
				}

				@media (min-width: emBreakpoint($desktopWidth)) {
					padding: 0 3.5rem 0 3.5rem;
					transform: translateY(-42%);
				}

				@media (min-width: emBreakpoint($pageWidth)) {
					padding: 0 3.5rem 0 3.5rem;
					transform: translateY(-60%);
				}

				h2 {
					@media (min-width: emBreakpoint($tabletWidth)) {
						text-align: left;
					}

					span {
						background: $magenta;
					}
				}

				p {
					@media (min-width: emBreakpoint($tabletWidth)) {
						text-align: left;
					}

					@media (min-width: emBreakpoint($pageWidth)) {
						margin-left: 0;
					}
				}
			}

			// .anchor-id {
			//	position: relative;
			//	top: -12rem;
			//	display: block;
			//	visibility: hidden;
			//
			//	@media (max-width: emBreakpoint($desktopWidth)) {
			//		top: -50rem;
			//	}
			//
			//	@media (max-width: emBreakpoint($tabletWidth)) {
			//		top: -40rem;
			//	}
			//
			//	@media (max-width: emBreakpoint($mobilePlusWidth)) {
			//		top: -30rem;
			//	}
			// }
		}
	}

	// section {
	//	max-width: $pageWidth;
	//	margin: auto;
	//	text-align: center;
	// }
	// section.video {
	//	margin-top: -7%;
	// }
	// section.content + section.video {
	//	margin-top: -14%;
	// }
	// section.content {
	//	position: relative;
	//	z-index: 3;
	//	margin-bottom: 9rem;
	//	padding-top: 0.1rem;
	//	padding-bottom: 7.5rem;
	//	transform-origin: top left;
	//	@include skew();
	//
	//	&:last-of-type {
	//		margin-bottom: 0;
	//	}
	//	> * {
	//		@include unskew();
	//	}
	//	h2 {
	//		margin-top: 5rem;
	//
	//		img {
	//			width: 18rem;
	//		}
	//	}
	//	p {
	//		max-width: $maxTextWidth;
	//		margin: 2rem auto 0 auto;
	//		padding: 0 1.5rem;
	//		font-size: 1.6rem;
	//		line-height: 1.4;
	//		text-align: left;
	//	}
	//	ul {
	//		max-width: 74rem;
	//		margin: 2rem auto 0 auto;
	//		padding: 0 1.5rem;
	//		font-size: 1.6rem;
	//		line-height: 1.4;
	//		text-align: left;
	//		list-style-type: square;
	//
	//		li {
	//			margin-bottom: 0.8rem;
	//			margin-left: 1.7rem;
	//		}
	//	}
	//	.btn {
	//		position: absolute;
	//		top: 100%;
	//		left: 50%;
	//		@include unskew(translateX(-50%) translateY(-50%));
	//	}
	//
	//	@media (min-width: emBreakpoint($mobilePlusWidth)) {
	//		p,
	//		ul {
	//			padding: 0 3rem;
	//		}
	//	}
	// }
	//
	// .image-gallery {
	//	margin-top: 4.5rem;
	//	padding: 3rem 1.5rem;
	//	font-size: 0;
	//	background: #fff;
	//
	//	@media (min-width: emBreakpoint($mobilePlusWidth)) {
	//		padding: 3rem;
	//	}
	//
	//	.description {
	//		a {
	//			display: none;
	//			color: #000;
	//			font-weight: $extraBoldFont;
	//			font-size: 1.7rem;
	//			line-height: 1.4;
	//			text-align: left;
	//
	//			&.selected {
	//				display: block;
	//			}
	//		}
	//	}
	//	.slides {
	//		margin-top: 1.5rem;
	//		overflow: hidden;
	//		background: #fff;
	//
	//		.slider {
	//			white-space: nowrap;
	//			transition: transform 0.3s;
	//
	//			div {
	//				position: relative;
	//				display: inline-block;
	//				width: 100%;
	//				padding-top: 75%;
	//			}
	//		}
	//		img {
	//			position: absolute;
	//			top: 50%;
	//			left: 50%;
	//			display: block;
	//			max-width: 100%;
	//			max-height: 100%;
	//			transform: translateX(-50%) translateY(-50%);
	//		}
	//	}
	//	.controls {
	//		margin-top: 2rem;
	//
	//		a {
	//			display: inline-block;
	//			width: 1.3rem;
	//			height: 1.3rem;
	//			margin: 0 0.9rem;
	//			background: #fff;
	//			border: 0.1rem solid #000;
	//			border-radius: 50%;
	//
	//			&:hover,
	//			&:focus,
	//			&:active {
	//				background: #000;
	//			}
	//			&.selected {
	//				background: #000;
	//			}
	//		}
	//	}
	// }
	// #bonopot {
	//	background: $transparentMagenta;
	//
	//	.btn {
	//		background: $magenta;
	//
	//		&:hover,
	//		&:focus,
	//		&:active {
	//			color: $magenta;
	//		}
	//	}
	// }
	// #posmio {
	//	background: $transparentAquamarine;
	//
	//	.btn {
	//		background: $aquamarine;
	//
	//		&:hover,
	//		&:focus,
	//		&:active {
	//			color: $aquamarine;
	//		}
	//	}
	// }
	//
	// #bonopot-header .spacer {
	//	background: #8a2d6e;
	// }
	// #posmio-header .spacer {
	//	background: #55c7c5;
	// }
	//
	// @media (min-width: emBreakpoint($tabletWidth)) {
	//	section.content {
	//		margin-bottom: 15rem;
	//		padding-bottom: 12rem;
	//
	//		&:last-of-type {
	//			margin-bottom: 5rem;
	//		}
	//		h2 {
	//			margin-top: 10rem;
	//		}
	//	}
	//	.image-gallery {
	//		max-width: $desktopWidth;
	//		margin: 7rem auto 0;
	//		padding: 6rem;
	//		overflow: auto;
	//		background: #fff;
	//
	//		.description {
	//			float: left;
	//			width: 40%;
	//
	//			a {
	//				display: block;
	//				padding-right: 2.5rem;
	//				color: rgba(0, 0, 0, 0.2);
	//				transition: color 0.3s;
	//
	//				&:after {
	//					display: block;
	//					width: 8%;
	//					height: 0.3rem;
	//					margin-top: 0.8rem;
	//					background-color: rgba(0, 0, 0, 0.2);
	//					transition: width 0.3s, background-color 0.3s;
	//					content: "";
	//				}
	//				&:hover,
	//				&:focus,
	//				&:active,
	//				&.selected {
	//					color: #000;
	//
	//					&:after {
	//						width: 24%;
	//						background-color: #000;
	//					}
	//				}
	//			}
	//			a + a {
	//				margin-top: 2rem;
	//			}
	//		}
	//		.slides {
	//			position: relative;
	//			float: right;
	//			width: 60%;
	//			margin-top: 0;
	//		}
	//		.controls {
	//			float: right;
	//			width: 60%;
	//			margin-top: 3rem;
	//		}
	//	}
	//	#bonopot {
	//		h2 img {
	//			width: 36.2rem;
	//		}
	//		.image-gallery .description a {
	//			&:hover,
	//			&:focus,
	//			&:active,
	//			&.selected {
	//				&:after {
	//					background-color: $magenta;
	//				}
	//			}
	//		}
	//	}
	//	#posmio {
	//		h2 img {
	//			width: 34.2rem;
	//		}
	//		.image-gallery .description a {
	//			&:hover,
	//			&:focus,
	//			&:active,
	//			&.selected {
	//				&:after {
	//					background-color: $aquamarine;
	//				}
	//			}
	//		}
	//	}
	// }
	// @media (min-width: emBreakpoint($pageWidth)) {
	//	section.video {
	//		margin-top: -10.1rem;
	//	}
	//	section.content + section.video {
	//		margin-top: -20.2rem;
	//	}
	// }
}
