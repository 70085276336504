section.error {
	position: relative;
	margin-top: 14%;
	padding-top: 34%;
	.skewer {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 115%;
		max-height: 37.4rem;
		background-color: $transparentMagenta;
		@include skew();
	}
	h2 {
		position: absolute;
		top: -70%;
		width: 100%;
		margin: 0;
		padding: 0;
		color: $magenta;
		font-weight: $extraBoldFont;
		font-size: 52vw;
		line-height: 1;
	}
	.err-img-wrapper {
		position: absolute;
		bottom: -7vw;
		left: 50%;
		z-index: 5;
		width: 100%;
		transform: translateX(-50%);
		transform-origin: left bottom;
		img {
			width: 100%;
			max-width: 86rem;
		}
		@media (min-width: 860px) {
			bottom: -60px;
		}
	}

	@media (min-width: emBreakpoint($desktopWidth)) {
		margin-top: 15.4rem;
		padding-top: 37.4rem;

		h2 {
			font-size: 57.2rem;
		}
		img {
			width: 70.4rem;
		}
	}
}

.home-link {
	text-decoration: underline;
	pointer-events: all;
	&:hover {
		text-decoration: none;
	}
}

.err-page {
	height: 100%;
	body {
		height: 100%;
	}
	header {
		padding-bottom: 2rem;
	}
}

#error {
	&:after {
		display: none;
	}
	&.err-main {
		margin-top: 0;
		.error {
			margin-top: 18rem;
		}
	}
	a {
		color: $magenta;
	}
	@media (min-width: emBreakpoint($tabletWidth)) {
		&:after {
			margin-top: -14rem;
		}
	}
}
