#contact {

	&:after {
		margin-top: -10rem;
	}

	// Mapa
	#map-container {
		max-width: $pageWidth;
		margin: auto;
		overflow: hidden;
		transform: none;

		// Mapa
		#map {
			display: none;
		}

		// Adresa a kontakt
		address {
			padding: 0 1.5rem 1.8rem;
			text-align: center;
			background: #fff;

			h2 {
				margin: 0 0 2rem;
				padding: 0;
				font-size: 2.8rem;
			}

			p {
				margin: 2rem 0;
				font-size: 1.6rem;
				line-height: 1.4;

				&:last-of-type {
					margin-bottom: 0;
				}
				a {
					color: inherit;

					&:hover,
					&:focus,
					&:active {
						text-decoration: underline;
					}
				}
			}

			// Odkaz na mapy
			> a {
				display: block;
				width: 20rem;
				margin: 4rem auto 0;
				padding: 1rem;
			}

			> a + a {
				margin-top: 1rem;
			}
		}
		@media (min-width: emBreakpoint($mobilePlusWidth)) {
			address {
				padding-right: 3rem;
				padding-left: 3rem;
			}
		}
	}

	@media (min-width: emBreakpoint($tabletWidth)) {
		#map-container {
			position: relative;
			margin-top: -6rem;

			&:before,
			&:after {
				position: absolute;
				z-index: 1;
				display: block;
				width: 100%;
				height: 25%;
				background: #fff;
				content: "";
				@include skew();
			}
			&:before {
				top: 0;
				transform-origin: top left;
			}
			&:after {
				bottom: 0;
				transform-origin: bottom right;
			}

			#map {
				display: block;
				width: 100%;
				padding-top: 56.25%;
			}
			address {
				position: absolute;
				top: 58%;
				left: 0;
				display: inline-block;
				padding: 5rem;
				text-align: left;
				box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.07);
				@include skew(translateY(-50%));

				> * {
					@include unskew();
				}
				h2 {
					text-align: left;
				}
				> a {
					display: none;
				}
			}

			// Stylovani bubliny v Google Maps
			.gm-style .gm-style-iw-c {
				padding: 1.6rem;
				color: #fff;
				font-weight: $boldFont;
				font-size: 1.6rem;
				font-family: "Open Sans", sans-serif;
				background: $magenta;
				border-radius: 0;
				box-shadow: none;
			}
			.gm-style .gm-style-iw-t:after {
				background: $magenta;
				box-shadow: none;
			}
			.gm-ui-hover-effect {
				img {
					display: none !important;
				}
				&:after {
					color: #fff;
					font-size: 1.6rem;
					content: "×";
				}
			}
		}
	}

	@media (min-width: emBreakpoint($desktopWidth)) {
		#map-container {
			margin-top: -9rem;
		}
	}

	// --------------------------------------------------------------------------------------------
	.contact-media-wrapper {
		margin-top: 5rem;
	}

	.contact-media {
		position: relative;
		height: 52rem;
		margin-top: 4.5rem;
		font-size: 1.6rem;
		text-align: left;
		background: $transparentMagenta;
		@include skew();

		> div {
			position: absolute;
			bottom: 0;
			left: 3.5rem;
			width: calc(100% - 7rem);
			height: 100%;
			@include unskew();

			@media (min-width: emBreakpoint($mobilePlusWidth)) {
				left: 5rem;
				width: calc(100% - 10rem);
			}

			> div {
				position: absolute;
				width: 100%;
				min-height: 100%;

				> div {
					position: absolute;
					top: 21rem;
					left: 50%;
					width: 100%;
					max-width: 30rem;
					text-align: center;
					transform: translateX(-50%);
				}
			}

			h3 {
				margin: auto;
				margin-bottom: 1rem;
				color: #fff;
				font-weight: $extraBoldFont;
				font-size: 2.2rem;
				text-align: center;
				text-transform: uppercase;

				@media (max-width: emBreakpoint($mobilePlusWidth)) {
					font-size: 1.9rem;
				}

				span {
					position: relative;
					display: inline-block;
					padding: 1rem 2.2rem;
					white-space: nowrap;
					background: $magenta;
				}
			}

			p {
				position: relative;
				display: block;
				color: $magenta;
				font-size: 1.6rem;
				line-height: 1.4;
				text-align: center;

				span {
					display: block;
				}

				a {
					color: $magenta;
				}
			}

			p:last-child {
				margin-top: 1rem;
			}

			img {
				position: absolute;
				bottom: calc(100% - 25rem);
				left: 50%;
				width: 23rem;
				transform: translateX(-50%);
				transform-origin: left bottom;
			}
		}

		@media (min-width: emBreakpoint($tabletWidth)) {
			max-width: $desktopWidth;
			height: 35rem;
			margin: 5rem auto 0;

			&:before,
			&:after {
				position: absolute;
				top: 0;
				display: block;
				width: 200rem;
				height: 100%;
				background: $transparentMagenta;
				content: "";
			}

			&:before {
				right: 100%;
			}

			&:after {
				left: 100%;
			}

			> div {
				left: 4rem;
				width: calc(100% - 8rem);
				transform: none;

				> div {
					> div {
						top: 23%;
						left: 50%;
						width: 50%;
						max-width: none;
						text-align: left;
						@include unskew();
					}
				}

				img {
					@include unskew();
					bottom: 0.4rem !important;
					left: auto;
				}

				h3 {
					margin-bottom: 1rem;
					font-size: 2.4rem;
					text-align: center;

					span {
						padding: 1rem 2.8rem;
					}
					span ~ span {
						margin-top: 1.5rem;
					}
				}

				p {
					display: block;
					margin-top: 0.7rem;
					font-size: 2.2rem;

					span {
						position: relative;
						display: block;
					}
				}

				p:last-child {
					margin-top: 2.5rem;
				}
			}

			.contact-media__block {
				img {
					right: 49%;
					width: 33.8rem;
				}
			}
		}
	}
}
